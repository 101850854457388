import { useState, useEffect } from 'react';
import api from 'services/api';
import { useIntl } from 'react-intl';
const useAsyncSelect = (url, searchedValue, searchedLabel, isTranslated, allOption) => {
	const { formatMessage } = useIntl();
	const [isLoading, setIsLoading] = useState(false);
	const [options, setOptions] = useState([]);
	const transformToSelect = data => {
		const selectValues = data
			.map(item => {
				let label = isTranslated
					? formatMessage({ id: `commons.${item[searchedLabel]}` })
					: item[searchedLabel];
				return {
					value: item[searchedValue],
					label: label,
				};
			})
			.filter(item => item !== undefined);
		if (allOption) {
			selectValues.unshift({ value: '', label: 'Todos' });
		}
		return selectValues;
	};

	const performFetch = async () => {
		try {
			setIsLoading(true);
			const response = await api.get(`${url}?pagination=false`);
			console.log('response:', response);
			if (response && response.length === 0) {
				setIsLoading(false);
				setOptions([{ value: 'No hay opciones', label: 'No hay opciones' }]);
			} else {
				const transformedData = transformToSelect(response);

				console.log('transformedData:', transformedData);
				setIsLoading(false);
				setOptions(transformedData);
			}
		} catch (error) {
			setOptions([{ value: 'No hay opciones', label: 'No hay opciones' }]);
		}
	};
	useEffect(() => {
		performFetch();
	}, [url]);
	return { isLoading, options };
};
export default useAsyncSelect;
