import BigImageLayout from '../components/common/BigImageLayout';
import ValidateText from '../components/register/ValidateText';
const Validate = () => {
	return (
		<BigImageLayout url="/images/step5.jpg">
			<ValidateText />
		</BigImageLayout>
	);
};

export default Validate;
