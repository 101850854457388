import { useContext } from 'react';
import { InsuranceContext } from '../../../context/insurance';
import styled from 'styled-components';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Grid from '../../common/Grid';
import Select from '../../common/forms/Select';
import Membership from '../../common/forms/Membership';
// import InputSlider from '../../common/forms/InputSlider';
import Input from '../../common/forms/Input';
import MembershipData from 'utils/MembershipData';
import userService from '../../../services/userService';
import Submit from '../../common/forms/Submit';
import { useIntl } from 'react-intl';

const Step1 = () => {
	const { state, dispatch } = useContext(InsuranceContext);
	const discount = userService.getSellerDiscount();
	const { formatMessage } = useIntl();
	return (
		<>
			<Intro>
				<h1>
					<span>{formatMessage({ id: 'forms.chooseMembership' })}</span>
				</h1>
			</Intro>
			<Formik
				initialValues={{
					membership: state.membership,
					city: state.city,
					compensationLimit: state.compensationLimit,
					discount: 0,
					policeName: state.policeName,
					montoFinanciamiento: state.montoFinanciamiento,
					gastosDeExpedicion: state.gastosDeExpedicion,
					IVA: state.IVA,
					primaNeta: state.primaNeta,
					primaTotal: state.primaTotal,
					primaPrimerPago: state.primaTotal,
					primaPagosSucesivos: 0,
				}}
				validationSchema={Yup.object({
					membership: Yup.string().required(formatMessage({ id: 'errors.required' })),
					city: Yup.string().required(formatMessage({ id: 'errors.required' })),
					compensationLimit: Yup.number()
						.min(100000, formatMessage({ id: 'errors.minimun' }))
						.max(7000000, formatMessage({ id: 'errors.maximum' })),
				})}
				onSubmit={async (values, { setSubmitting }) => {
					setSubmitting(true);

					dispatch({
						step: 4,
						membership: values.membership,
						city: values.city,
						compensationLimit: values.compensationLimit,
						policeName: values.policeName,
						montoFinanciamiento: values.montoFinanciamiento,
						gastosDeExpedicion: values.gastosDeExpedicion,
						IVA: values.IVA,
						primaNeta: values.primaNeta,
						primaTotal: values.primaTotal,
						primaPrimerPago: values.primaPrimerPago,
						primaPagosSucesivos: values.primaPagosSucesivos,
					});
				}}>
				{formik => (
					<form onSubmit={formik.handleSubmit}>
						<Grid columns="1">
							<Select
								name="city"
								label={formatMessage({ id: 'forms.selectCity' })}
								setFieldValue={formik.setFieldValue}
								options={[
									{ value: '1', label: 'Riviera Nayarit - Puerto Vallarta' },
									{ value: '2', label: 'Los Cabos' },
									{ value: '3', label: 'Cozumel' },
									{ value: '4', label: 'Cancún' },
									{ value: '5', label: 'Playa del Carmen' },
									{ value: '6', label: 'Tulum' },
								]}
							/>
							<Membership
								name="membership"
								label={formatMessage({ id: 'forms.selectMembership' })}
								setFieldValue={formik.setFieldValue}
								options={[
									{ value: '1', label: `Sandy - $250,000 ${formatMessage({ id: 'commons.mxn' })}` },
									{
										value: '2',
										label: `Michelle - $500,000 ${formatMessage({ id: 'commons.mxn' })}`,
									},
									{
										value: '3',
										label: `Katrina - $650,000 ${formatMessage({ id: 'commons.mxn' })}`,
									},
									{
										value: '4',
										label: `Wilma - ${formatMessage({ id: 'opt.customized' })}`,
									},
								]}
							/>
							{formik.values.membership === '4' && (
								<Input
									type="number"
									name="compensationLimit"
									label={formatMessage({ id: 'forms.compensationLimit' })}
									placeholder={formatMessage({ id: 'forms.compensationLimit' })}
									value={formik.values.compensationLimit}
								/>
							)}
							<Limit>
								<MembershipData
									city={formik.values.city}
									membership={formik.values.membership}
									discount={discount}
									compensationLimit={formik.values.compensationLimit}
									setFieldValue={formik.setFieldValue}
									values={formik.values}
								/>
							</Limit>
							<Submit disable={formik.isSubmitting}>{formatMessage({ id: 'commons.next' })}</Submit>
							{/* <pre>{JSON.stringify(state, null, 4)}</pre> */}
							{/* <pre>{JSON.stringify(formik.values, null, 4)}</pre>
							<pre>{JSON.stringify(formik.errors, null, 4)}</pre> */}
						</Grid>
					</form>
				)}
			</Formik>
		</>
	);
};
const Intro = styled.div`
	h1 {
		font-size: 2rem;
		font-weight: 700;

		margin-bottom: 1em;
	}
	span {
		color: ${({ theme }) => theme.primaryColor};
	}
	p {
		font-size: 1.2rem;
		margin-bottom: 1em;
	}
`;
const Limit = styled.div`
	padding-left: 5px;
	span {
		font-weight: 700;
		font-size: 2em;
		color: ${({ theme }) => theme.primaryColor};
	}
`;

export default Step1;
