import BigImageLayout from '../components/common/BigImageLayout';
import ForgotPasswordForm from '../components/forgotPassword/Form';
const ForgotPassword = () => {
	return (
		<BigImageLayout url="/images/login.png">
			<ForgotPasswordForm />
		</BigImageLayout>
	);
};

export default ForgotPassword;
