import BigImageLayout from '../components/common/BigImageLayout';
import RecoveryPasswordForm from '../components/recoveryPassword/Form';
const ForgotPassword = () => {
	return (
		<BigImageLayout url="/images/login.png">
			<RecoveryPasswordForm />
		</BigImageLayout>
	);
};

export default ForgotPassword;
