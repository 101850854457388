import useAsyncSelect from 'hooks/useAsyncSelect';
import { Field } from 'formik';
import styled from 'styled-components';
import Select from 'react-select';

const OperatorsSelect = props => {
	const { name, label, endpoint, searchedValue, searchedLabel, isTranslated, setFieldValue } =
		props;
	const { isLoading, options } = useAsyncSelect(
		endpoint,
		searchedValue,
		searchedLabel,
		isTranslated
	);
	const handleChange = e => {
		setFieldValue(name, e.value);
	};
	return (
		<>
			<Field name={name}>
				{formik => {
					return (
						<>
							{isLoading && (
								<InputWrap>
									{label && <Label>{label}</Label>}
									<MyStyledSelect
										placeholder={label}
										isSearchable={false}
										onChange={handleChange}
										classNamePrefix={`mySelect`}
										className={formik.meta.touched && formik.meta.error ? 'error' : ''}
										noOptionsMessage={() => 'No hay opciones'}
										isLoading={isLoading}
										isDisabled={isLoading}
									/>
									{formik.meta.touched && formik.meta.error && (
										<MyError>{formik.meta.error}</MyError>
									)}
								</InputWrap>
							)}
							{!isLoading && (
								<InputWrap>
									{label && <Label>{label}</Label>}
									<MyStyledSelect
										defaultValue={
											options[options.map(option => option.value).indexOf(formik.field.value)]
										}
										placeholder={label}
										isSearchable={false}
										options={options}
										onChange={handleChange}
										classNamePrefix={`mySelect`}
										className={formik.meta.touched && formik.meta.error ? 'error' : ''}
										noOptionsMessage={() => 'No hay opciones'}
										isLoading={isLoading}
										isDisabled={isLoading}
									/>
									{formik.meta.touched && formik.meta.error && (
										<MyError>{formik.meta.error}</MyError>
									)}
								</InputWrap>
							)}
						</>
					);
				}}
			</Field>
		</>
	);
};

const InputWrap = styled.div`
	width: 100%;
	margin-bottom: 1em;
	position: relative;
	label {
		margin-bottom: 0.5em;
		padding-left: 5px;
	}
`;
const MyError = styled.div`
	color: red;
	padding: 0.3em;
	position: absolute;
	top: 70px;
	font-size: 10px !important;
	z-index: 1;
`;
const MyStyledSelect = styled(Select)`
	.mySelect__control {
		border-color: ${props => props.theme.separatorColor};
		background-color: #fff;
		box-shadow: none !important;
		height: 45.5px !important;
		&:hover {
			border-color: ${props => props.theme.separatorColor}!important;
			outline: none !important;
			box-shadow: 'none' !important;
		}
	}
	.mySelect__value-container {
		color: ${props => props.theme.fontColor};
	}
	.mySelect__menu {
		background-color: ${props => props.theme.separatorColor};
		position: absolute;
		z-index: 9999;
		margin-top: 1px;
	}
	.mySelect__option {
		background-color: transparent;
		color: ${props => props.theme.fontColor};
		&:hover,
		&:active {
			background-color: ${props => props.theme.body};
		}
		&:active {
			background-color: ${props => props.theme.body};
		}
		&.mySelect__option--is-selected {
			background-color: ${props => props.theme.body};
		}
	}
	.mySelect__single-value {
		color: ${props => props.theme.fontColor};
	}
	&:focus {
		outline: none;
	}
	&.error {
		.mySelect__control {
			border-color: red;
			&:hover {
				border-color: red;
			}
		}
	}
`;
const Label = styled.label`
	display: flex !important;
	align-items: center;
`;

export default OperatorsSelect;
