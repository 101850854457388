import Router from './Router';
import { ThemeProvider } from 'styled-components';
import { LightTheme } from './css/colors-styled';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
function App() {
	return (
		<ThemeProvider theme={LightTheme}>
			<ToastContainer
				position="top-left"
				autoClose={3000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
			<Router />
		</ThemeProvider>
	);
}

export default App;
