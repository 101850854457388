import Content from 'components/common/Content-v2';
import MainTitle from 'components/common/Title';
import LinkButton from 'components/common/LinkButton';
import { useFetch } from 'hooks/useFetch';
import Skeleton from 'components/common/Skeleton';
import { useParams } from 'react-router-dom';
import EditMembersForm from 'components/forms/members/edit';
const EditMember = props => {
	const { id } = useParams();
	const { data, isLoading } = useFetch(`user/${id}`);
	return (
		<>
			<MainTitle>
				<h1>Editar usuario</h1>
				<LinkButton to="/admin/members"> Regresar</LinkButton>
			</MainTitle>
			<Content>
				{isLoading && <Skeleton count={10} />}
				{!isLoading && <EditMembersForm {...data} />}
			</Content>
		</>
	);
};
export default EditMember;
