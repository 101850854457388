import { useState, useEffect, useCallback } from 'react';
import api from '../services/api';

export const useDashboardData = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [response, setResponse] = useState([]);

	const fetchData = useCallback(async () => {
		try {
			setIsLoading(true);
			let response = await api.get('police/dashboard');
			setResponse(response);
			setIsLoading(false);
		} catch (err) {
			console.log(err);
		}
	}, []);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	return {
		isLoading,
		response,
	};
};
