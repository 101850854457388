import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './css/globals.scss';
import { GlobalsProvider } from './context/globals';
import Intl from './context/Intl';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<BrowserRouter>
			<GlobalsProvider>
				<Intl>
					<App />
				</Intl>
			</GlobalsProvider>
		</BrowserRouter>
	</React.StrictMode>
);
