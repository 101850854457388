import styled from 'styled-components';
import { Menu, MenuItem } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import { useToogleLanguage } from 'hooks/useToogleLanguage';
const LanguageChanger = props => {
	const { isRelative = false } = props;
	const { languageChanger, state } = useToogleLanguage();
	return (
		<Wrap className={`${isRelative ? 'relative' : ''}`}>
			<Menu
				menuButton={
					<FlagButton>
						<img
							src={`/images/flags/${state}.png`}
							alt=""
						/>
						<p>{state === 'es' ? 'Español' : 'English'}</p>
						<i className="material-icons-outlined">keyboard_arrow_down</i>
					</FlagButton>
				}
				transition
				direction="bottom"
				align="end"
				position="auto"
				arrow={true}>
				<MenuItem>
					{state !== 'es' && (
						<MenuItemWrap onClick={() => languageChanger('es')}>Español</MenuItemWrap>
					)}
					{state !== 'en' && (
						<MenuItemWrap onClick={() => languageChanger('en')}>English</MenuItemWrap>
					)}
				</MenuItem>
			</Menu>
		</Wrap>
	);
};

const Wrap = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	margin-right: 1em;
	&.relative {
		position: absolute;
		width: 100px;
		right: 50%;
		transform: translateY(-50%);
	}
	h2 {
		margin-bottom: 0;
		font-weight: 400;
		font-size: 0.8rem;
		color: ${props => props.theme.contentFontColor};
		margin-right: 1em;
	}
`;
const FlagButton = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	p {
		margin: 0 5px;
	}
	img {
		width: 20px;
		height: 20px;
	}
`;
export const MenuItemWrap = styled.div`
	width: 100%;
`;

export default LanguageChanger;
