import BigImageLayout from '../components/common/BigImageLayout';
import { Intro } from '../components/common/styles/Intro';
import styled from 'styled-components';
import Button from '../components/common/Button';
const PaySuccess = () => {
	return (
		<BigImageLayout url="/images/login.png">
			<Wrap>
				<Intro>
					<h1>Su pago ha sido registrado con exito</h1>
					<p>Ve a tu cuenta para poder descargar tu poliza en la sección de polizas activas</p>
					<Button to="/">Ir a mi cuenta</Button>
				</Intro>
			</Wrap>
		</BigImageLayout>
	);
};
const Wrap = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
`;

export default PaySuccess;
