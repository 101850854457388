import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import api from 'services/api';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import Input from 'components/common/forms/Input';
import Submit from 'components/common/forms/Submit';
import Grid from 'components/common/Grid';
const ManualPaymemtForm = props => {
	let navigate = useNavigate();
	return (
		<Formik
			initialValues={{
				paymentId: '',
			}}
			validationSchema={Yup.object({
				paymentId: Yup.string().required('Campo requerido'),
			})}
			onSubmit={(values, { setSubmitting, resetForm }) => {
				setSubmitting(true);
				api
					.get(`/mercado-pago/payment-info?paymentId=${values.paymentId}`)
					.then(res => {
						toast.success('Pago actualizado correctamente');
						resetForm();
						setSubmitting(false);  // Asegúrate de cambiar el estado de envío después de una respuesta exitosa
					})
					.catch(err => {
						console.log(err);

						// Comprobamos si hay una respuesta y un mensaje de error específico
						if (err.response?.data?.message === 'Rerencia no encontrada') {
							toast.error('Referencia no encontrada');  // Corrige el error tipográfico
						} else if (err.response?.data?.message === 'La póliza ya está activa') {
							toast.error('La póliza ya está activa');
						} else {
							toast.error('Error inesperado');
						}

						setSubmitting(false);
					});
			}}>
			{formik => (
				<>
					<MyForm onSubmit={formik.handleSubmit}>
						<Grid columns="1">
							<Input
								name="paymentId"
								type="text"
								label="ID del pago"
								placeholder="Ingresa el ID del pago"
							/>
						</Grid>
						<Submit
							type="submit"
							margin="3em auto 0 auto "
							disabled={formik.isSubmitting}>
							Registrar
						</Submit>
					</MyForm>
				</>
			)}
		</Formik>
	);
};

const MyForm = styled.form`
	padding: 1em 0 2em 0;
	width: 100%;
`;

export default ManualPaymemtForm;
