import BigImageLayout from '../components/common/BigImageLayout';
import LoginForm from '../components/Login/Form';
const Login = () => {
	return (
		<BigImageLayout url="/images/login.jpg">
			<LoginForm />
		</BigImageLayout>
	);
};

export default Login;
