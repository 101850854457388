import styled from 'styled-components';
import Content from '../../common/Content-v2';
import userService from '../../../services/userService';
import { formatPhoneNumber } from 'utils/formatters';
import { useIntl } from 'react-intl';
const Profile = () => {
	const user = userService.getUser();
	const { formatMessage } = useIntl();
	return (
		<>
			<div>
				<Title>{formatMessage({ id: 'account.title' })}</Title>
				<Content margin="30px">
					<DataPart>
						<h3>{formatMessage({ id: 'table.name' })}</h3>
						<p className="capilatize">
							{user.name} {user.lastName}
						</p>
					</DataPart>
					<DataPart>
						<h3>{formatMessage({ id: 'table.seller' })}</h3>
						<p className="capilatize">{user.membership}</p>
					</DataPart>
					<DataPart>
						<h3>{formatMessage({ id: 'table.email' })}</h3>
						<p>{user.email}</p>
					</DataPart>
					<DataPart>
						<h3>{formatMessage({ id: 'table.phone' })}</h3>
						<p>{formatPhoneNumber(`${user.telephone}`)}</p>
					</DataPart>
				</Content>
			</div>
		</>
	);
};
const DataPart = styled.div`
	margin-bottom: 1em;
	&:last-child {
		margin-bottom: 0;
	}
	h3 {
		font-size: 16px;
		color: #d3d4d6;
		margin: 0;
		text-transform: uppercase;
	}
	p {
		font-size: 19px;
		margin: 0;
	}
	p.capilatize {
		text-transform: capitalize;
	}
`;
const Title = styled.h2`
	padding-left: 10px;
	font-size: 1.5rem;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	overflow: hidden;
`;

export default Profile;
