import styled from 'styled-components';
import AvatarMenu from './AvatarMenu';
import LanguageChanger from './languageChanger';

const Header = props => {
	return (
		<HeaderWrap>
			<LeftContent>
				<img
					src="/images/logo_wilma.png"
					alt="Wilma"
				/>
			</LeftContent>
			<RightContent>
				<LanguageChanger />
				<AvatarMenu />
			</RightContent>
		</HeaderWrap>
	);
};
const HeaderWrap = styled.header`
	width: 100%;
	height: 60px;
	padding: 0 3.5%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: fixed;
	z-index: 1030;
	background-color: ${props => props.theme.background};
	color: ${props => props.theme.fontColor};
	${props => {
		if (props.theme.name === 'light') {
			return 'box-shadow: 0 1px 15px rgba(0,0,0,.04),0 1px 6px rgba(0,0,0,.04);';
		} else {
			return 'box-shadow: 0 1px 15px rgba(0,0,0,.1),0 1px 8px rgba(0,0,0,.1);';
		}
	}}
`;
const LeftContent = styled.div`
	width: 45%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 5px;
	img {
		widht: 30px;
		height: 30px;
	}
	p {
		font-weight: bold;
		font-size: 16px;
	}
`;
const RightContent = styled.div`
	width: 45%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
`;

export default Header;
