import React from 'react';
import styled from 'styled-components';
const MainTitle = props => {
	return <Wrap>{props.children}</Wrap>;
};
const Wrap = styled.div`
	width: 100%;
	border-bottom: 1px solid ${props => props.theme.separatorColor};
	margin-bottom: 2rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-bottom: 10px;
	h1,
	h2 {
		min-width: 50%;
		font-size: 1.75rem;
		margin: 0;
		padding: 0;
	}
`;
export default MainTitle;
