import React from 'react';
import styled from 'styled-components';
import LoadingSmall from './LoadingSmall';
import { Link } from 'react-router-dom';
const Submit = props => {
	const { to } = props;
	return (
		<>
			{to && (
				<Wrap
					margin={props.margin}
					justify={props.justify}>
					<Link to={to}>
						<MyButton
							disabled={props.disabled}
							{...props}>
							{props.disabled ? <LoadingSmall /> : props.children}
						</MyButton>
					</Link>
				</Wrap>
			)}
			{!to && (
				<Wrap
					margin={props.margin}
					justify={props.justify}>
					<MyButton
						disabled={props.disabled}
						{...props}>
						{props.disabled ? <LoadingSmall /> : props.children}
					</MyButton>
				</Wrap>
			)}
		</>
	);
};
const Wrap = styled.div`
	width: 100%;
	margin: ${({ margin }) => (margin ? margin : '2em')};
	display: flex;
	justify-content: ${({ justify }) => (justify ? justify : 'center')};
`;
const MyButton = styled.button`
	min-width: 50%;
	line-height: 1.5;
	font-weight: 700;
	letter-spacing: 0.05rem;
	padding: 0.75rem 2.6rem 0.6rem 2.6rem;
	background-color: ${props => props.theme.primaryColor};
	color: #fff;
	border-radius: 50px;
	transition: all 0.2s ease-in;
	border: none;
	cursor: pointer;
	@media screen and (max-width: 768px) {
		padding: 0.75rem 0.5rem 0.6rem 0.5rem;
		line-height: 1;
		min-width: 40%;
	}
	&:hover {
		color: #fff;
		background-color: ${props => props.theme.primaryHoverColor};
	}
	&:disabled {
		opacity: 0.5;
		&:hover {
			background-color: ${props => props.theme.primaryColor};
		}
	}
`;

export default Submit;
