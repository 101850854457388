import { InsuranceProvider } from '../context/insurance';
import Steps from '../components/createInsurance';
const CreateInsurance = () => {
	return (
		<InsuranceProvider>
			<Steps />
		</InsuranceProvider>
	);
};

export default CreateInsurance;
