import styled from 'styled-components';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Grid from '../common/Grid';
import Password from '../common/forms/Password';
import Submit from '../common/forms/Submit';
import api from '../../services/api';
import { toast } from 'react-toastify';
import { useNavigate, useSearchParams } from 'react-router-dom';
const Form = () => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const token = searchParams.get('token');
	return (
		<Wrap>
			<Logo>
				<img
					src="/images/wilma.png"
					alt="Wilma"
				/>
			</Logo>
			<Title>
				<h1>
					Cambia tu <span>contraseña</span>
				</h1>
				<p>Llena el formulario para generar una nueva contraseña.</p>
			</Title>
			<Formik
				initialValues={{
					password: '',
					confirmPassword: '',
				}}
				validationSchema={Yup.object({
					password: Yup.string().min(6, 'Mínimo 6 caracteres').required('Campo requerido'),
					confirmPassword: Yup.string()
						.oneOf([Yup.ref('password'), null], 'Las contraseñas deben coincidir')
						.required('Campo requerido'),
				})}
				onSubmit={async (values, { setSubmitting }) => {
					setSubmitting(true);
					try {
						delete values.confirmPassword;
						console.log(values);
						await api.post(`/email-confirmation/changePassword/${token}`, values);
						toast.success('Contraseña cambiada exitosamente. Inicia sesión.');
						navigate('/login');
						setSubmitting(false);
					} catch (error) {
						toast.error('Ocurrió un error, intenta de nuevo');
						setSubmitting(false);
					}
				}}>
				{formik => (
					<form onSubmit={formik.handleSubmit}>
						<Grid columns="1">
							<Password
								name="password"
								type="password"
								label="Contraseña"
								placeholder="Ingresa tu nueva contraseña"
							/>
							<Password
								name="confirmPassword"
								type="password"
								label="Confirma tu contraseña"
								placeholder="Confirma tu contraseña"
							/>
						</Grid>
						<Extra>
							<Grid
								columns="2"
								align="center">
								<div>
									<ForgotPassword href="/login">Inicia sesión</ForgotPassword>
								</div>
								<Submit
									margin="0"
									justify="end"
									disabled={formik.isSubmitting}>
									Cambiar contraseña
								</Submit>
							</Grid>
						</Extra>
					</form>
				)}
			</Formik>
		</Wrap>
	);
};

const Wrap = styled.div`
	height: 100%;
`;

const Logo = styled.div`
	margin-bottom: 2em;
	img {
		display: block;
		max-width: 140px;
	}
`;

const Title = styled.div`
	margin-top: 100px;
	margin-bottom: 40px;
	h1 {
		font-size: 2.5rem;
		font-weight: bold;
		color: #000;
		margin: 0;
		span {
			color: ${props => props.theme.primaryColor};
		}
	}
	p {
		margin: 0;
		font-size: 1.25rem;
		color: #000;
	}
	@media screen and (max-width: 768px) {
	}
`;
const Extra = styled.div`
	margin-top: 2em;
`;
const ForgotPassword = styled.a`
	color: ${props => props.theme.primaryColor};
	font-weight: bold;
	font-size: 16px;
	text-decoration: underline;
	display: block;
	margin-bottom: 5px;
	&:hover {
		color: ${props => props.theme.primaryHoverColor};
	}
	@media screen and (max-width: 768px) {
		font-size: 10px;
	}
`;

export default Form;
