import BigImageLayout from '../components/common/BigImageLayout';
import Success from '../components/register/Success';

const Register = () => {
	return (
		<BigImageLayout url="/images/step5.jpg">
			<Success />
		</BigImageLayout>
	);
};

export default Register;
