import Grid from 'components/common/Grid';
import GaugeV2 from 'components/common/charts/gaugev2';
import styled from 'styled-components';
import { convertCity } from 'utils/formUtils';
import formatNumber from 'utils/formatNumber';
import userService from 'services/userService';
const Goals = props => {
	const { capacity, montoFinanciamiento, netPremium, primaNeta, memberships, polices, citys } =
		props;
	const user = userService.getUser();
	return (
		<Grid columns="3">
			<div>
				<GaugeV2
					title={user.role === 'root' ? 'Capacidad' : 'Objetivo suma asegurada'}
					value={montoFinanciamiento}
					limit={capacity}
				/>
				<Total>
					<p>${formatNumber(montoFinanciamiento)}</p>
				</Total>
				{citys.map(city => (
					<Info key={city.city}>
						<p>{convertCity(city.city)}</p>
						<p>${formatNumber(city['montoFinanciamiento'])}</p>
					</Info>
				))}
			</div>
			<div>
				<GaugeV2
					title={user.role === 'root' ? 'Prima neta' : 'Objetivo prima neta'}
					value={primaNeta}
					limit={netPremium}
				/>
				<Total>
					<p>${formatNumber(primaNeta)}</p>
				</Total>
				{citys.map(city => (
					<Info key={city.city}>
						<p>{convertCity(city.city)}</p>
						<p>${formatNumber(city['primaNeta'])}</p>
					</Info>
				))}
			</div>
			<div>
				<GaugeV2
					title={user.role === 'root' ? 'Membresías' : 'Objetivo membresías'}
					value={polices}
					limit={memberships}
				/>
				<Total>
					<p>{formatNumber(polices)}</p>
				</Total>
				{citys.map(city => (
					<Info key={city.city}>
						<p>{convertCity(city.city)}</p>
						<p>{formatNumber(city['polices'])}</p>
					</Info>
				))}
			</div>
		</Grid>
	);
};
const Info = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 0.5rem;
	border-bottom: 1px solid #ccc;
`;
const Total = styled.div`
	display: flex;
	justify-content: center;
	margin-bottom: 1rem;
	p {
		background-color: ${props => props.theme.primaryColor};
		color: white;
		display: inline-block;
		padding: 0.5rem 1rem;
		border-radius: 0.5rem;
	}
`;
export default Goals;
