export const LightTheme = {
	name: 'light',
	background: '#FFF',
	baseBackground: '#FFF',
	body: '#f8f8f8',
	selected: '#fbfbfb',
	primaryColor: 'rgb(12 163 171)',
	primaryHoverColor: '#17b565',
	primaryUnselectedColor: '#21f088',
	fontColor: '#3a3a3a',
	contentFontColor: '#878787',
	separatorColor: '#d7d7d7',
	menuLines: '#f3f3f3',
	success: '#97CECC',
};
export const DarkTheme = {
	name: 'dark',
	background: '#242322',
	baseBackground: '#1e1e1e',
	body: '#1b1a19',
	selected: '#252422',
	primaryColor: '#1ad376',
	primaryHoverColor: '#17b565',
	primaryUnselectedColor: '#21f088',
	fontColor: '#8f8f8f',
	contentFontColor: '#878787',
	separatorColor: '#424242',
	menuLines: '#313131',
	success: '#97CECC',
};
