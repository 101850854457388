import { useState } from 'react';
import { Field } from 'formik';
import styled from 'styled-components';
const Input = ({ name, placeholder, label, disabled, maxlength, quitError, toUpperCase }) => {
	const [isVisible, setIsVisible] = useState(false);
	return (
		<>
			<Field name={name}>
				{({ field, meta }) => (
					<InputWrap>
						{label && <Label>{label}</Label>}
						<MyStyledInput
							type={isVisible ? 'text' : 'password'}
							placeholder={placeholder}
							className={meta.touched && meta.error ? 'error' : ''}
							disabled={disabled}
							maxLength={maxlength}
							toUpperCase={toUpperCase}
							{...field}
						/>
						<PassEye onClick={() => setIsVisible(!isVisible)}>
							{isVisible ? (
								<i className="material-icons-outlined">visibility_off</i>
							) : (
								<i className="material-icons-outlined">visibility</i>
							)}
						</PassEye>
						{meta.touched && meta.error && !quitError && <MyError>{meta.error}</MyError>}
					</InputWrap>
				)}
			</Field>
		</>
	);
};
const InputWrap = styled.div`
	width: 100%;
	position: relative;
	label {
		margin-bottom: 0.5em;
		display: block;
		padding-left: 5px;
	}
`;
const MyStyledInput = styled.input`
	padding: 1em 0.8em;
	width: 100%;
	display: block;
	background: #fff;
	border: 1px solid ${props => props.theme.separatorColor};
	border-radius: 5px;
	outline: none;
	color: ${props => props.theme.fontColor};
	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	&.error {
		border: 1px solid red;
	}
	&:-webkit-autofill {
		appearance: inherit;
		background-color: inherit !important;
		background-image: none !important;
		color: inherit !important;
	}
	&::placeholder {
		/* Chrome, Firefox, Opera, Safari 10.1+ */
		color: ${props => props.theme.separatorColor};
		opacity: 1; /* Firefox */
		text-transform: none !important;
	}
	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	/* Firefox */
	&[type='number'] {
		-moz-appearance: textfield;
	}
	${props => {
		if (props.toUpperCase) {
			return `text-transform: uppercase;`;
		}
	}}
`;
const MyError = styled.div`
	color: red;
	padding: 0.3em;
	position: absolute;
	top: 100%;
	transform: translateY(-2px);
	font-size: 10px;
	z-index: 9996;
`;
const Label = styled.label`
	display: flex !important;
	align-items: center;
`;
export const PassEye = styled.div`
	position: absolute;
	width: 20px;
	height: 20px;
	cursor: pointer;
	top: 40px;
	right: 12px;
`;
export default Input;
