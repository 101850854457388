import { Intro } from '../common/styles/Intro';
import styled from 'styled-components';
import { useSearchParams } from 'react-router-dom';
import { useLoginWithToken } from '../../hooks/useLoginWithToken';
import Button from '../common/Button';
const RegisterForm = () => {
	const [searchParams] = useSearchParams();
	const { isLoading, hasError } = useLoginWithToken(searchParams.get('token'));
	return (
		<>
			<Wrap>
				<Intro>
					<h1>
						¡Felicidades ya eres parte de <span>Wilma</span>
					</h1>
					<p>Hemos creado una cuenta para ti.</p>
					<p>Con el siguiente botón podrás comenzar a llenar tu solicitud:</p>
				</Intro>
				<Button
					disabled={isLoading}
					margin="2em 0 0 0"
					justify="center"
					to={hasError ? '/login' : '/crear-poliza'}>
					{hasError ? 'Login' : 'Comenzar'}
				</Button>
			</Wrap>
		</>
	);
};
const Wrap = styled.div`
	display: flex;
	justify-content: center;
	flex-direction: column;
	height: 100%;
`;

export default RegisterForm;
