import styled from 'styled-components';

const CircleButton = props => {
	const { icon } = props;
	return (
		<Button {...props}>
			<i className="material-icons-outlined">{icon}</i>
		</Button>
	);
};

const Button = styled.button`
	width: 35px;
	height: 35px;
	cursor: pointer;
	border: none;
	outline: none;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	background: ${({ theme }) => theme.primaryColor};
	transition: all 0.3s ease-in-out;
	i {
		font-size: 1.2rem;
		color: #fff;
		line-height: 1rem;
		transition: transform 0.3s ease-in-out;
	}
`;

export default CircleButton;
