import userService from '../../services/userService';
const Switcher = () => {
	const role = userService.getRole();
	if (role === 'client') {
		window.location.href = '/members';
	} else {
		window.location.href = '/admin';
	}
	return null;
};
export default Switcher;
