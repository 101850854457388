import Title from 'components/common/Title';
import { useIntl } from 'react-intl';
import Content from 'components/common/Content-v2';
import MembershipsTable from 'components/tables/memberships';
import CircleButton from 'components/common/ButtonIcon';
const Memberships = () => {
	const { formatMessage } = useIntl();
	const allyId = localStorage.getItem('allyId');

	const handleBack = () => {
		localStorage.removeItem('allyId');
		window.location.reload();
	};
	return (
		<>
			<Title>
				<h1>{formatMessage({ id: 'memberships.title' })}</h1>
				{allyId && (
					<CircleButton
						type="button"
						icon="arrow_back"
						onClick={() => handleBack()}>
						{formatMessage({ id: 'commons.back' })}
					</CircleButton>
				)}
			</Title>
			<Content>
				<MembershipsTable />
			</Content>
		</>
	);
};
export default Memberships;
