import { useDataTable } from '../../../hooks/useDataTable';
import StyledTable from '../../common/StyledTable';
import moment from 'moment';
import ClientOptions from './options';
import DownloadReport from '../../common/DownloadReport';
import { useIntl } from 'react-intl';
import userService from 'services/userService';
const UsersTable = () => {
	const userRole = userService.getRole();
	const { isLoading, response, totalDocs, perPage, handlePageChange, handlePerRowsChange } =
		useDataTable('police/search', [
			{ name: 'quote', value: 'true' },
			{ name: 'active', value: 'true' },
		]);
	const { formatMessage } = useIntl();
	const columns = [
		{
			name: formatMessage({ id: 'table.folio' }),
			selector: row => row.quotation,
			sortable: true,
			grow: 1,
			wrap: true,
		},
		{
			name: formatMessage({ id: 'table.company' }),
			selector: row => row.businessName,
			sortable: true,
			grow: 1,
			wrap: true,
		},
		{
			name: formatMessage({ id: 'table.insuredAmount' }),
			selector: row => row.montoFinanciamiento,
			sortable: true,
			grow: 1,
			wrap: true,
		},
		{
			name: formatMessage({ id: 'table.premiumAmount' }),
			selector: row => row.primaTotal,
			sortable: true,
			grow: 1,
			wrap: true,
		},
		{
			name: formatMessage({ id: 'table.membershipType' }),
			selector: row => row.policeName,
			sortable: true,
			grow: 1,
			wrap: true,
		},
		{
			name: formatMessage({ id: 'table.seller' }),
			selector: row =>
				`${row?.seller?.membership || '-'} - ${row?.seller?.name || '-'} ${row?.seller?.lastName || '-'}`,
			sortable: true,
			wrap: true,
		},
	];
	return (
		<>
			<StyledTable
				data={response}
				columns={columns}
				progressPending={isLoading}
				pagination
				paginationServer
				paginationTotalRows={totalDocs}
				onChangeRowsPerPage={handlePerRowsChange}
				onChangePage={handlePageChange}
				noDataComponent={<div>No hay datos</div>}
				paginationPerPage={perPage}
			/>
			{userRole === 'root' && (
				<DownloadReport
					justify="flex-end"
					endpoint="/user/clients-csv"
					name={formatMessage({ id: 'commons.downloadAll' })}
				/>
			)}
		</>
	);
};
export default UsersTable;
