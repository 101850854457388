import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import userService from '../services/userService';

const Private = () => {
	const [isLoading, setIsLoading] = useState(true);
	useEffect(() => {
		if (userService.isLogged()) {
			setIsLoading(false);
		} else {
			userService.logOut();
		}
	}, []);
	return <>{!isLoading && <Outlet />}</>;
};
export default Private;
