import Content from 'components/common/Content-v2';
import MainTitle from 'components/common/Title';
import LinkButton from 'components/common/LinkButton';
import AddMemberForm from 'components/forms/members/add';
import { useIntl } from 'react-intl';
const AddMember = props => {
	const { formatMessage } = useIntl();
	return (
		<>
			<MainTitle>
				<h1>{formatMessage({ id: 'dashboard.addMember' })}</h1>
				<LinkButton to="/admin/members"> {formatMessage({ id: 'commons.back' })} </LinkButton>
			</MainTitle>
			<Content>
				<AddMemberForm />
			</Content>
		</>
	);
};
export default AddMember;
