import React, { useReducer, createContext } from 'react';

const initialState = {
	step: 1,
	businessName: '',
	streetAndNumber: '',
	state: '',
	companyCity: '',
	colony: '',
	zipCode: '',
	rfc: '',
	businessLine: '',
	commercialName: '',
	numberOfEmployees: '',
	largeRevenue: '',
	agree: false,
	membership: '',
	city: '',
	compensationLimit: '',
	policeName: '',
	montoFinanciamiento: '',
	gastosDeExpedicion: '',
	IVA: '',
	primaNeta: '',
	primaTotal: '',
	primaPrimerPago: '',
	primaPagosSucesivos: '',
	policeId: '',
};
// eslint-disable-next-line
const testState = {
	step: 4,
	businessName: 'Mi empresa',
	streetAndNumber: 'Amilcar Vidal 6',
	state: 'CDMX',
	companyCity: 'CDMX',
	colony: 'Constitución de la República',
	zipCode: '09260',
	rfc: 'MRM860820NN1',
	businessLine: '1',
	commercialName: 'test',
	numberOfEmployees: '0 a 10',
	largeRevenue: 'no',
	agree: true,
	membership: '1',
	city: '2',
	compensationLimit: '250000',
	policeName: 'Sandy',
	montoFinanciamiento: '250000',
	gastosDeExpedicion: 400,
	IVA: 3450.6672,
	primaNeta: 28750.6672,
	primaTotal: 28750.6672,
	primaPrimerPago: 2395.88893333,
	primaPagosSucesivos: 2395.8889333,
	policeId: '664ac9f04c73f41bf925a884',
};

// const testState = {
//   step: 3,
//   businessName: 'TEST',
//   businessAdress: 'Amilcar Vidal',
//   businessLine: '1',
//   commercialName: 'Kono comercial name',
//   numberOfEmployees: '5',
//   businessMail: 'miguelr@kono.mx',
//   rfc: 'MORM860820NN1',
//   membership: '1',
//   city: '2',
//   compensationLimit:'250000',
//   companyValue: '1',
//   ownershipType: 'own',
//   propertyValue: '1',
//   coverageType: '1',
//   coverQuantity: '2',
//   largeRevenue: 'yes',
//   policeName: '',
//   amount: '',
//   agree: true,
//   policeId: '6418ebb5d37c91701c6a23c0'
// }

function reducer(state, action) {
	return { ...state, ...action };
}

const InsuranceContext = createContext();

const InsuranceProvider = ({ children }) => {
	const [state, dispatch] = useReducer(reducer, initialState);
	return (
		<InsuranceContext.Provider value={{ state, dispatch }}>{children}</InsuranceContext.Provider>
	);
};

export { InsuranceContext, InsuranceProvider };
