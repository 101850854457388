import React from 'react';
import styled from 'styled-components';
import { withTheme } from 'styled-components';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
const RecetaSkeleton = ({ theme, count }) => {
	return (
		<SkeletonTheme
			color={theme.body}
			highlightColor={theme.background}>
			<Wrap>
				<Skeleton count={count} />
			</Wrap>
		</SkeletonTheme>
	);
};
const Wrap = styled.div`
	width: 100%;
	background: ${props => props.theme.background};
	.react-loading-skeleton {
		margin-bottom: 0.5rem;
	}
`;
export default withTheme(RecetaSkeleton);
