import React from 'react';
import Select from 'react-select';
import { Field } from 'formik';
import styled from 'styled-components';
import { convertMembership } from 'utils/formUtils';
const MySelect = props => {
	const { label, name, options, setFieldValue, disabled } = props;

	const handleChange = item => {
		setFieldValue(name, item.value);
		setFieldValue('policeName', convertMembership(item.value));
		if (item.value === '1') {
			setFieldValue('compensationLimit', '250000');
		}
		if (item.value === '2') {
			setFieldValue('compensationLimit', '500000');
		}
		if (item.value === '3') {
			setFieldValue('compensationLimit', '650000');
		}
		if (item.value === '4') {
			setFieldValue('compensationLimit', '100000');
		}
	};
	return (
		<Field name={name}>
			{({ field, form: { touched, errors }, meta }) => (
				<InputWrap>
					{label && <Label>{label}</Label>}
					<MyStyledSelect
						defaultValue={options[options.map(option => option.value).indexOf(field.value)]}
						placeholder={label}
						isSearchable={false}
						classNamePrefix={'mySelect'}
						noOptionsMessage={() => 'No hay opciones'}
						options={options}
						isDisabled={disabled}
						onChange={handleChange}
						className={meta.touched && meta.error ? 'error' : ''}
					/>
					{meta.touched && meta.error && <MyError>{meta.error}</MyError>}
				</InputWrap>
			)}
		</Field>
	);
};
const InputWrap = styled.div`
	width: 100%;
	margin-bottom: 1em;
	position: relative;
	label {
		margin-bottom: 0.5em;
		padding-left: 5px;
	}
`;
const MyError = styled.div`
	color: red;
	padding: 0.3em;
	position: absolute;
	top: 70px;
	font-size: 10px !important;
	z-index: 1;
`;
const MyStyledSelect = styled(Select)`
	.mySelect__control {
		border-color: ${props => props.theme.separatorColor};
		background-color: #fff;
		box-shadow: none !important;
		height: 45.5px !important;
		&:hover {
			border-color: ${props => props.theme.separatorColor}!important;
			outline: none !important;
			box-shadow: 'none' !important;
		}
	}
	.mySelect__value-container {
		color: ${props => props.theme.fontColor};
	}
	.mySelect__menu {
		background-color: ${props => props.theme.separatorColor};
		position: absolute;
		z-index: 9999;
		margin-top: 1px;
	}
	.mySelect__option {
		background-color: transparent;
		color: ${props => props.theme.fontColor};
		&:hover,
		&:active {
			background-color: ${props => props.theme.body};
		}
		&:active {
			background-color: ${props => props.theme.body};
		}
		&.mySelect__option--is-selected {
			background-color: ${props => props.theme.body};
		}
	}
	.mySelect__single-value {
		color: ${props => props.theme.fontColor};
	}
	&:focus {
		outline: none;
	}
	&.error {
		.mySelect__control {
			border-color: red;
			&:hover {
				border-color: red;
			}
		}
	}
`;
const Label = styled.label`
	display: flex !important;
	align-items: center;
`;
export default MySelect;
