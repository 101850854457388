import styled from 'styled-components';
const MapData = props => {
	const { x, y, value } = props;
	return (
		<Wrap
			x={x}
			y={y}>
			<p>{value}</p>
		</Wrap>
	);
};

const Wrap = styled.div`
	position: absolute;
	top: ${props => props.y}px;
	left: ${props => props.x}px;
	background: ${props => props.theme.primaryColor};
	padding: 0.25rem 0.5rem;
	border-radius: 10%;
	color: white;
	box-shadow: 0px 0px 23px 5px rgba(0, 0, 0, 0.61);
	font-size: 1rem;
	font-weight: bolder;
`;

export default MapData;
