import styled from 'styled-components';
import moment from 'moment';
import { useIntl } from 'react-intl';
const Policy = props => {
	const { policeName, client, validFrom, expirationDate, _id, police } = props;
	const { formatMessage } = useIntl();
	return (
		<Wrap>
			<Intro>
				<Logo>
					<img
						src={`/images/membership/${policeName.toLowerCase()}.jpg`}
						alt={policeName}
					/>
				</Logo>
				<DownLoad>
					<a
						href={`${process.env.REACT_APP_API}/pdf/download/${_id}`}
						target="_Blank"
						rel="noreferrer">
						<i className="material-icons-outlined">file_download</i>
					</a>
				</DownLoad>
			</Intro>
			<Data>
				<h2>{client.name}</h2>
				<p>
					<span>{formatMessage({ id: 'account.validFrom' })}:</span>{' '}
					{moment(validFrom).format('DD/MM/YYYY')}
				</p>
				<p>
					<span>{formatMessage({ id: 'account.validUntil' })}:</span>{' '}
					{moment(expirationDate).format('DD/MM/YYYY')}
				</p>
				<p>
					<span>{formatMessage({ id: 'account.policy' })}:</span> {police}
				</p>
			</Data>
		</Wrap>
	);
};
const Wrap = styled.article`
	background-image: url('images/tarjeta.jpg');
	background-size: cover;
	min-height: 220px;
	border-radius: 15px;
	margin: 0 10px;
	color: #fff;
`;
const Intro = styled.div`
	display: grid;
	grid-template-columns: 80% 10% 10%;
	align-items: center;
	padding: 10px;
`;
const Logo = styled.div`
	width: 60px;
	height: 60px;
	background: #fff;
	border-radius: 50%;
	margin: 0 10px;
	position: relative;
	overflow: hidden;
	img {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
`;
const DownLoad = styled.div`
	i {
		font-size: 2.5rem;
		color: #fff;
	}
`;
const Data = styled.div`
	padding: 20px;
	h2 {
		font-size: 1.5rem;
	}
	p {
		span {
			font-weight: 700;
		}
	}
`;

export default Policy;
