import { useContext } from 'react';
import { InsuranceContext } from '../../../context/insurance';
import styled from 'styled-components';
import Button from '../../common/Button';
import { useCreateSubscription } from '../../../hooks/useCreateSubscription';
const Step5 = () => {
	const { state } = useContext(InsuranceContext);
	const { createSubscription, isLoading } = useCreateSubscription(state.policeId);
	return (
		<>
			<Intro>
				<h1>
					¡Gracias por <span>completar el formulario</span>!
				</h1>
				<p>
					Ya hemos registrado tus datos y apartado tu suscripción de <span>Wilma</span> para la
					temporada de <span>huracán 2023</span>
				</p>
				<p>
					La temporada de huracán en tu zona inicia el <span>1 de junio 2023</span>, por lo que te
					contactaremos en <span>abril 2023</span> para que puedas terminar tu contratación
				</p>
			</Intro>
			<Nav>
				<Button
					onClick={createSubscription}
					margin="0">
					Pagar
				</Button>
				<Button
					to="/"
					margin="0">
					Mi cuenta
				</Button>
			</Nav>
		</>
	);
};
const Intro = styled.div`
	h1 {
		font-size: 2rem;
		font-weight: 700;

		margin-bottom: 1em;
	}
	span {
		color: ${({ theme }) => theme.primaryColor};
	}
	p {
		font-size: 1.2rem;
		margin-bottom: 1em;
	}
`;
const Data = styled.div`
	h2 {
		font-size: 1.2rem;
		margin: 0;
	}
	p {
		font-size: 2rem;
		color: ${({ theme }) => theme.primaryColor};
		font-weight: 700;
	}
	padding-bottom: 1em;
	margin-bottom: 1em;
	border-bottom: 1px solid #ccc;
`;
const Nav = styled.div`
	display: flex;
	justify-content: center;
	gap: 1em;
	margin-top: 2em;
`;

export default Step5;
