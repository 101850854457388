import styled from 'styled-components';
import MapData from './mapData';
const Map = props => {
	const { citys, partnerCount, guideCount, membersCount, quotes } = props;
	return (
		<Wrap>
			<IconsColumn>
				<IconData>
					<h3>{partnerCount}</h3>
					<img
						src="/images/map/allies.png"
						alt="Aliados"
					/>
					<h3>Aliados</h3>
				</IconData>
				<IconData>
					<h3>{guideCount}</h3>
					<img
						src="/images/map/guides.png"
						alt="Aliados"
					/>
					<h3>Guías</h3>
				</IconData>
			</IconsColumn>
			<MapBackground>
				<MapData
					x={350}
					y={330}
					value={citys.find(city => city.city === '1')?.polices || 0}
				/>
				<MapData
					x={190}
					y={190}
					value={citys.find(city => city.city === '2')?.polices || 0}
				/>
				<MapData
					x={750}
					y={305}
					value={citys.find(city => city.city === '3')?.polices || 0}
				/>
				<MapData
					x={680}
					y={270}
					value={citys.find(city => city.city === '4')?.polices || 0}
				/>
				<MapData
					x={700}
					y={310}
					value={citys.find(city => city.city === '5')?.polices || 0}
				/>
				<MapData
					x={670}
					y={350}
					value={citys.find(city => city.city === '6')?.polices || 0}
				/>
			</MapBackground>
			<IconsColumn>
				<IconData>
					<h3>{membersCount}</h3>
					<img
						src="/images/map/members.png"
						alt="Aliados"
					/>
					<h3>Miembros</h3>
				</IconData>
				<IconData>
					<h3>{quotes}</h3>
					<img
						src="/images/map/quotes.png"
						alt="Aliados"
					/>
					<h3>Cotizaciones</h3>
				</IconData>
			</IconsColumn>
		</Wrap>
	);
};

const Wrap = styled.div`
	margin-top: 4rem;
	background-color: #94d3d0;
	padding: 1rem;
	min-height: 500px;
	display: grid;
	grid-template-columns: 1fr 800px 1fr;
	grid-gap: 1rem;
`;
const MapBackground = styled.div`
	background: url(/images/map.png) no-repeat center center;
	background-size: cover;
	position: relative;
`;
const IconsColumn = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 1rem 0;
`;
const IconData = styled.div`
	color: #fff;
	h3 {
		font-size: 1.5rem;
		text-align: center;
	}
	img {
		display: block;
		width: 100%;
		margin: 0 auto;
		max-width: 100px;
	}
`;
export default Map;
