import styled from 'styled-components';
import { useToogleLanguage } from '../../../hooks/useToogleLanguage';
const ChangeLanguage = () => {
	const { languageChanger, state } = useToogleLanguage();

	return (
		<Wrap>
			{state !== 'es' && <button onClick={() => languageChanger('es')}>Español</button>}
			{state !== 'en' && <button onClick={() => languageChanger('en')}>English</button>}
		</Wrap>
	);
};
const Wrap = styled.div`
	display: flex;
	justify-content: flex-end;
	button {
		background: none;
		border: none;
		cursor: pointer;
		color: ${props => props.theme.primaryColor};
		font-size: 0.8rem;
		margin-right: 1em;
	}
`;

export default ChangeLanguage;
