import styled from 'styled-components';

export const Wrap = styled.ul`
	padding: 1em 0;
	li {
		list-style: none;
		display: block;
		position: relative;
		a {
			height: 100px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			font-size: 13px;
			font-style: normal;
			font-weight: 400;
			transition: none;
			border-bottom: 1px solid ${props => props.theme.menuLines};
			color: ${props => props.theme.fontColor};
			transition: border-color 0.000001s ease-in-out;
			i {
				font-size: 22px;
				line-height: 22px;
			}
			&.active {
				color: ${props => props.theme.primaryColor};
			}
			&.active::after {
				content: ' ';
				background: ${props => props.theme.primaryColor};
				color: #fff;
				border-radius: 10px;
				position: absolute;
				width: 6px;
				height: 90px;
				top: 50%;
				transform: translateY(-50%);
				left: 0;
			}
		}
		a:hover {
			color: ${props => props.theme.primaryColor};
		}
	}
	li:nth-child(1) a {
		border-top: 1px solid ${props => props.theme.menuLines};
	}
`;
