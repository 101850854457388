import { useContext } from 'react';
import { InsuranceContext } from '../../../context/insurance';
import styled from 'styled-components';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Grid from '../../common/Grid';
import Input from '../../common/forms/Input';
import Select from '../../common/forms/Select';
import Submit from '../../common/forms/Submit';
import { useIntl } from 'react-intl';
const Step1 = () => {
	const { state, dispatch } = useContext(InsuranceContext);
	const { formatMessage } = useIntl();
	return (
		<>
			<Intro>
				<h1>
					<span>{formatMessage({ id: 'forms.tellUs' })}</span>
				</h1>
			</Intro>
			<Formik
				initialValues={{
					businessName: state.businessName,
					streetAndNumber: state.streetAndNumber,
					state: state.state,
					companyCity: state.companyCity,
					colony: state.colony,
					zipCode: state.zipCode,
					rfc: state.rfc,
				}}
				validationSchema={Yup.object({
					businessName: Yup.string().required(formatMessage({ id: 'errors.required' })),
					streetAndNumber: Yup.string().required(formatMessage({ id: 'errors.required' })),
					state: Yup.string().required(formatMessage({ id: 'errors.required' })),
					companyCity: Yup.string().required(formatMessage({ id: 'errors.required' })),
					colony: Yup.string().required(formatMessage({ id: 'errors.required' })),
					zipCode: Yup.string().required(formatMessage({ id: 'errors.required' })),
					rfc: Yup.string().required(formatMessage({ id: 'errors.required' })),
				})}
				onSubmit={(values, { setSubmitting }) => {
					setSubmitting(true);
					dispatch({
						step: 2,
						businessName: values.businessName,
						streetAndNumber: values.streetAndNumber,
						state: values.state,
						companyCity: values.companyCity,
						colony: values.colony,
						zipCode: values.zipCode,
						rfc: values.rfc.toUpperCase(),
					});
				}}>
				{formik => (
					<form onSubmit={formik.handleSubmit}>
						<Grid columns="1">
							<Input
								name="businessName"
								type="text"
								label={formatMessage({ id: 'forms.contractor' })}
								placeholder={formatMessage({ id: 'forms.contractorLabel' })}
							/>
							<Input
								name="streetAndNumber"
								type="text"
								label={formatMessage({ id: 'forms.streetAndNumber' })}
								placeholder={formatMessage({ id: 'forms.streetAndNumberLabel' })}
							/>
							<Select
								name="state"
								label={formatMessage({ id: 'forms.state' })}
								setFieldValue={formik.setFieldValue}
								options={[
									{ value: 'Aguascalientes', label: 'Aguascalientes' },
									{ value: 'Baja California', label: 'Baja California' },
									{ value: 'Baja California Sur', label: 'Baja California Sur' },
									{ value: 'Campeche', label: 'Campeche' },
									{ value: 'Chiapas', label: 'Chiapas' },
									{ value: 'Chihuahua', label: 'Chihuahua' },
									{ value: 'Coahuila', label: 'Coahuila' },
									{ value: 'Colima', label: 'Colima' },
									{ value: 'CDMX', label: 'CDMX' },
									{ value: 'Durango', label: 'Durango' },
									{ value: 'Guanajuato', label: 'Guanajuato' },
									{ value: 'Guerrero', label: 'Guerrero' },
									{ value: 'Hidalgo', label: 'Hidalgo' },
									{ value: 'Jalisco', label: 'Jalisco' },
									{ value: 'Estado de México', label: 'Estado de México' },
									{ value: 'Michoacán', label: 'Michoacán' },
									{ value: 'Morelos', label: 'Morelos' },
									{ value: 'Nayarit', label: 'Nayarit' },
									{ value: 'Nuevo León', label: 'Nuevo León' },
									{ value: 'Oaxaca', label: 'Oaxaca' },
									{ value: 'Puebla', label: 'Puebla' },
									{ value: 'Querétaro', label: 'Querétaro' },
									{ value: 'Quintana Roo', label: 'Quintana Roo' },
									{ value: 'San Luis Potosí', label: 'San Luis Potosí' },
									{ value: 'Sinaloa', label: 'Sinaloa' },
									{ value: 'Sonora', label: 'Sonora' },
									{ value: 'Tabasco', label: 'Tabasco' },
									{ value: 'Tamaulipas', label: 'Tamaulipas' },
									{ value: 'Tlaxcala', label: 'Tlaxcala' },
									{ value: 'Veracruz', label: 'Veracruz' },
									{ value: 'Yucatán', label: 'Yucatán' },
									{ value: 'Zacatecas', label: 'Zacatecas' },
								]}
							/>
							<Input
								name="companyCity"
								type="text"
								label={formatMessage({ id: 'forms.companyCity' })}
								placeholder={formatMessage({ id: 'forms.companyCityLabel' })}
							/>
							<Input
								name="colony"
								type="text"
								label={formatMessage({ id: 'forms.colony' })}
								placeholder={formatMessage({ id: 'forms.colonyLabel' })}
							/>
							<Input
								name="zipCode"
								type="text"
								label={formatMessage({ id: 'forms.zipCode' })}
								placeholder={formatMessage({ id: 'forms.zipCodeLabel' })}
							/>
							<Input
								name="rfc"
								type="text"
								toUpperCase={true}
								label={formatMessage({ id: 'forms.rfc' })}
								placeholder={formatMessage({ id: 'forms.rfcLabel' })}
							/>
							{/* <pre>{JSON.stringify( formik.values  , null, 4)}</pre>
              <pre>{JSON.stringify( formik.errors  , null, 4)}</pre> */}
							<Submit disable={formik.isSubmitting}>{formatMessage({ id: 'commons.next' })}</Submit>
						</Grid>
					</form>
				)}
			</Formik>
		</>
	);
};
const Intro = styled.div`
	h1 {
		font-size: 2rem;
		font-weight: 700;

		margin-bottom: 1em;
	}
	span {
		color: ${({ theme }) => theme.primaryColor};
	}
	p {
		font-size: 1.2rem;
		margin-bottom: 1em;
	}
`;
const Limit = styled.div`
	padding-left: 5px;
	span {
		font-weight: 700;
		font-size: 2em;
		color: ${({ theme }) => theme.primaryColor};
	}
`;
export default Step1;
