import React from 'react';
import { Field } from 'formik';
import styled from 'styled-components';
const Radio = props => {
	const { name, label, disabled, image, itemValue, imageSize, quitImage } = props;
	return (
		<Field
			type="radio"
			name={name}
			value={itemValue}
			id={label}>
			{({ field, form: { touched, errors }, meta }) => (
				<InputWrap>
					<MyStyledInput
						name={name}
						type="radio"
						value={itemValue}
						id={label}
						{...field}
					/>
					<label
						className={meta.touched && meta.error ? 'error' : ''}
						disabled={disabled}
						htmlFor={label}>
						{label}
						{!quitImage && (
							<Thumbnail imageSize={imageSize}>
								<img
									src={image}
									alt={name}
								/>
							</Thumbnail>
						)}
					</label>
				</InputWrap>
			)}
		</Field>
	);
};
const InputWrap = styled.div`
	width: 100%;
	margin-right: 20px;
	background: #fff;
	label {
		display: flex;
		width: 100%;
		justify-content: space-between;
		align-items: center;
		padding: 15px;
		border: 1px solid ${props => props.theme.separatorColor};
		border-radius: 5px;
		font-weight: 500;
		cursor: pointer;
		&.error {
			border: 1px solid red;
		}
	}
	input[type='radio'] {
	}
	input[type='radio']:checked + label {
		border: 1px solid ${props => props.theme.primaryColor};
	}
`;
const Thumbnail = styled.div`
	width: 50px;
	height: 50px;
	border-radius: 5px;
	background-color: black;
	overflow: hidden;
	color: black;
	img {
		width: 100%;
		height: 100%;
		object-fit: ${props => (props.imageSize ? props.imageSize : 'cover')};
	}
`;
const MyStyledInput = styled.input`
	display: none;
`;
export default Radio;
