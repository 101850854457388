import Title from 'components/common/Title';
import LinkButton from 'components/common/LinkButton';
import { useIntl } from 'react-intl';
import Content from 'components/common/Content-v2';
import AlliesTable from 'components/tables/allies';
import userService from 'services/userService';
import CircleButton from 'components/common/ButtonIcon';
const Allies = () => {
	const { formatMessage } = useIntl();
	const userRole = userService.getRole();
	const allyId = localStorage.getItem('allyId');

	const handleBack = () => {
		localStorage.removeItem('allyId');
		window.location.reload();
	};
	return (
		<>
			<Title>
				<h1>{formatMessage({ id: 'allies.title' })}</h1>
				{userRole === 'root' && (
					<LinkButton to="/admin/allies/add">{formatMessage({ id: 'commons.add' })}</LinkButton>
				)}
				{allyId && (
					<CircleButton
						type="button"
						icon="arrow_back"
						onClick={() => handleBack()}>
						{formatMessage({ id: 'commons.back' })}
					</CircleButton>
				)}
			</Title>
			<Content>
				<AlliesTable />
			</Content>
		</>
	);
};
export default Allies;
