import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
const CategorySlider = props => {
	const { children } = props;
	const settings = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 5000,
		cssEase: 'linear',
		arrows: true,
		nextArrow: <NextArrow />,
		prevArrow: <PrevArrow />,
	};
	return (
		<Wrap>
			<Slider {...settings}>{children}</Slider>
		</Wrap>
	);
};
const NextArrow = props => {
	const { onClick } = props;
	return (
		<ArrowNext onClick={onClick}>
			<i className="material-icons-outlined">arrow_forward_ios</i>
		</ArrowNext>
	);
};
const PrevArrow = props => {
	const { onClick } = props;
	return (
		<ArrowPrev onClick={onClick}>
			<i className="material-icons-outlined">arrow_back_ios</i>
		</ArrowPrev>
	);
};
const Wrap = styled.div`
	position: relative;
	padding: 20px;
`;

const ArrowNext = styled.div`
	position: absolute;
	z-index: 6666;
	background-color: ${props => props.theme.primaryColor};
	color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 20px;
	width: 40px;
	height: 40px;
	top: 50%;
	right: 0;
	transform: translateX(35px);
	cursor: pointer;
	i {
		margin-left: 3px;
	}
`;
const ArrowPrev = styled.div`
	position: absolute;
	z-index: 6666;
	background-color: ${props => props.theme.primaryColor};
	color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 20px;
	width: 40px;
	height: 40px;
	top: 50%;
	left: 0;
	transform: translateX(-35px);
	cursor: pointer;
	i {
		margin-right: 3px;
	}
`;

export default CategorySlider;
