import { useEffect, useState } from 'react';
import api from '../services/api';
export const useLoginWithToken = token => {
	const [isLoading, setIsLoading] = useState(true);
	const [hasError, setHasError] = useState(false);
	useEffect(() => {
		localStorage.setItem('token', token);
		const getUSer = async () => {
			try {
				let user = await api.get(`/auth/current-user`);
				if (!user.validatedMail) {
					await api.post(`/email-confirmation/confirm`, {
						token: token,
					});
				}
				setIsLoading(false);
			} catch (error) {
				setHasError(true);
				setIsLoading(false);
			}
		};
		getUSer();
	});
	return { isLoading, hasError };
};
