import styled from 'styled-components';
import { Link } from 'react-router-dom';
const LinkButton = ({ to, children }) => {
	return <Wrap to={to}>{children}</Wrap>;
};
const Wrap = styled(Link)`
	line-height: 1.5;
	font-weight: 700;
	letter-spacing: 0.05rem;
	padding: 0.75rem 2.6rem 0.6rem 2.6rem;
	background-color: ${props => props.theme.primaryColor};
	color: #fff;
	border-radius: 50px;
	transition: all 0.2s ease-in;
	&:hover {
		color: #fff;
		background-color: ${props => props.theme.primaryHoverColor};
	}
`;
export default LinkButton;
