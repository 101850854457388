import { useState, useEffect } from 'react';
import api from '../services/api';
export const useFetch = url => {
	const [data, setData] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await api.get(url);
				setData(response);
				setIsLoading(false);
			} catch (error) {
				console.log(error);
			}
		};
		fetchData();
	}, [url]);
	return { data, isLoading };
};
