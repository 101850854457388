import styled from 'styled-components';
export const Intro = styled.div`
	h1 {
		font-size: 2rem;
		font-weight: 700;
		margin-bottom: 1rem;
		margin: 0;
	}
	span {
		color: ${({ theme }) => theme.primaryColor};
	}
	p {
		font-size: 1.2rem;
	}
	p:last-child {
		margin-bottom: 2em;
	}
`;
