import { Routes, Route } from 'react-router-dom';
import Switcher from './components/switcher';
import Login from './pages/Login';
import AdminLayout from './layout/adminLayout';
import Layout from './layout';
import Register from './pages/Register';
import RegisterSuccessfull from './pages/RegisterSuccessfull';
import Validate from './pages/Validate';
import CreateInsurance from './pages/CreateInsurance';
import Private from './layout/Private';
import PaySuccess from './pages/PaySuccess';
import ForgotPassword from './pages/forgot-password';
import RecoveryPassword from './pages/recovery-password';
import ClientDash from './components/dashboards/clientDash';
// admin
import Dashboard from './pages/dashboard';
import Members from './pages/members';
import MembersAdd from './pages/members/add';
import MembersEdit from './pages/members/edit';
import Guides from './pages/guides';
import GuidesAdd from './pages/guides/add';
import GuidesEdit from './pages/guides/edit';
import Allies from './pages/allies';
import AlliesAdd from './pages/allies/add';
import AlliesEdit from './pages/allies/edit';
import Membershiops from './pages/memberships';
import Quotes from './pages/quotes';
import QuotesAdd from './pages/quotes/add';
import QuotesEdit from './pages/quotes/edit';
import Claims from './pages/claims';
import ManualPayment from './pages/manualPayment';
const Router = () => {
	return (
		<Routes>
			<Route
				path="/"
				element={<Switcher />}
			/>
			<Route
				path="/admin"
				element={<AdminLayout />}>
				<Route
					index
					element={<Dashboard />}
				/>
				<Route
					path="/admin/members/"
					element={<Members />}
				/>
				<Route
					path="/admin/members/add"
					element={<MembersAdd />}
				/>
				<Route
					path="/admin/members/edit/:id"
					element={<MembersEdit />}
				/>
				<Route
					path="/admin/allies/"
					element={<Allies />}
				/>
				<Route
					path="/admin/allies/add"
					element={<AlliesAdd />}
				/>
				<Route
					path="/admin/allies/edit/:id"
					element={<AlliesEdit />}
				/>
				<Route
					path="/admin/guides/"
					element={<Guides />}
				/>
				<Route
					path="/admin/guides/add"
					element={<GuidesAdd />}
				/>
				<Route
					path="/admin/guides/edit/:id"
					element={<GuidesEdit />}
				/>
				<Route
					path="/admin/memberships/"
					element={<Membershiops />}
				/>
				<Route
					path="/admin/quotes/"
					element={<Quotes />}
				/>
				<Route
					path="/admin/quotes/add"
					element={<QuotesAdd />}
				/>
				<Route
					path="/admin/quotes/edit/:id"
					element={<QuotesEdit />}
				/>
				<Route
					path="/admin/claims"
					element={<Claims />}
				/>
				<Route
					path="/admin/manual-payment"
					element={<ManualPayment />}
				/>
			</Route>
			<Route
				path="/members"
				element={<Layout />}>
				<Route
					index
					element={<ClientDash />}
				/>
			</Route>
			<Route
				path="/crear-poliza"
				element={<Private />}>
				<Route
					index
					element={<CreateInsurance />}
				/>
			</Route>
			<Route
				path="/pago-exitoso"
				element={<PaySuccess />}
			/>
			<Route
				path="/login"
				element={<Login />}
			/>
			<Route
				path="/forgot-password"
				element={<ForgotPassword />}
			/>
			<Route
				path="/recoveryPassword"
				element={<RecoveryPassword />}
			/>
			<Route
				path="/registro"
				element={<Register />}
			/>
			<Route
				path="/registro-exitoso"
				element={<RegisterSuccessfull />}
			/>
			<Route
				path="/validate"
				element={<Validate />}
			/>
		</Routes>
	);
};

export default Router;
