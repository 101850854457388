import api from './api';
const userService = {
	setToken: token => {
		if (token) {
			localStorage.setItem('token', token);
			api.defaults.headers.authorization = `Bearer ${token}`;
		}
	},
	setUser: user => {
		localStorage.setItem('user', JSON.stringify(user));
		return true;
	},
	getUser() {
		let response = localStorage.getItem('user');
		if (response) {
			return JSON.parse(response);
		}
		return null;
	},
	getRole() {
		let response = localStorage.getItem('user');
		if (response) {
			return JSON.parse(response).role;
		}
		return null;
	},
	getToken: () => {
		return localStorage.getItem('token');
	},
	isLogged: () => {
		const token = localStorage.getItem('token');
		if (token) {
			api.defaults.headers.authorization = `Bearer ${token}`;
			return true;
		} else {
			return false;
		}
	},
	getSellerDiscount: () => {
		let user = JSON.parse(localStorage.getItem('user'));
		if (user?.role === 'root') return 0;
		if (user?.role === 'seller') return user.discount;
		return user?.seller?.discount || 0;
	},
	logOut: async () => {
		localStorage.clear();
		localStorage.removeItem('token');
		localStorage.removeItem('user');
		delete api.defaults.headers.authorization;
		window.location.replace('/login');
		return true;
	},
};
export default userService;
