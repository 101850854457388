import { useContext } from 'react';
import { InsuranceContext } from '../../../context/insurance';
import styled from 'styled-components';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Grid from '../../common/Grid';
import Select from '../../common/forms/Select';
import Submit from '../../common/forms/Submit';
import Input from '../../common/forms/Input';
import Radio from '../../common/forms/Radio';
import { useIntl } from 'react-intl';
const Step3 = () => {
	const { state, dispatch } = useContext(InsuranceContext);
	const { formatMessage } = useIntl();
	return (
		<>
			<Intro>
				<h1>
					<span>{formatMessage({ id: 'forms.tellUs' })}</span>
				</h1>
			</Intro>
			<Formik
				initialValues={{
					businessLine: state.businessLine,
					commercialName: state.commercialName,
					numberOfEmployees: state.numberOfEmployees,
					largeRevenue: state.largeRevenue,
				}}
				validationSchema={Yup.object({
					businessLine: Yup.string().required(formatMessage({ id: 'errors.required' })),
					commercialName: Yup.string().when('businessLine', {
						is: value => value !== '12',
						then: Yup.string().required(formatMessage({ id: 'errors.required' })),
					}),
					numberOfEmployees: Yup.string().when('businessLine', {
						is: value => value !== '12',
						then: Yup.string().required(formatMessage({ id: 'errors.required' })),
					}),
					largeRevenue: Yup.string().required(formatMessage({ id: 'errors.required' })),
				})}
				onSubmit={(values, { setSubmitting }) => {
					setSubmitting(true);
					dispatch({
						step: 3,
						businessLine: values.businessLine,
						commercialName: values.commercialName,
						numberOfEmployees: values.numberOfEmployees,
						largeRevenue: values.largeRevenue,
					});
				}}>
				{formik => (
					<form onSubmit={formik.handleSubmit}>
						<Grid columns="1">
							<Select
								name="businessLine"
								label={formatMessage({ id: 'forms.businessLine' })}
								setFieldValue={formik.setFieldValue}
								options={[
									{ value: '1', label: formatMessage({ id: 'opt.businessLine.1' }) },
									{ value: '2', label: formatMessage({ id: 'opt.businessLine.2' }) },
									{ value: '3', label: formatMessage({ id: 'opt.businessLine.3' }) },
									{ value: '4', label: formatMessage({ id: 'opt.businessLine.4' }) },
									{ value: '5', label: formatMessage({ id: 'opt.businessLine.5' }) },
									{ value: '6', label: formatMessage({ id: 'opt.businessLine.6' }) },
									{ value: '7', label: formatMessage({ id: 'opt.businessLine.7' }) },
									{ value: '8', label: formatMessage({ id: 'opt.businessLine.8' }) },
									{ value: '9', label: formatMessage({ id: 'opt.businessLine.9' }) },
									{ value: '10', label: formatMessage({ id: 'opt.businessLine.10' }) },
									{ value: '12', label: formatMessage({ id: 'opt.businessLine.12' }) },
									{ value: '11', label: formatMessage({ id: 'opt.businessLine.11' }) },
								]}
							/>
							{formik.values.businessLine !== '12' && (
								<>
									<Input
										name="commercialName"
										type="text"
										label={formatMessage({ id: 'forms.commercialName' })}
										placeholder={formatMessage({ id: 'forms.commercialNameLabel' })}
									/>
									<Select
										name="numberOfEmployees"
										label={formatMessage({ id: 'forms.numberOfEmployees' })}
										setFieldValue={formik.setFieldValue}
										options={[
											{ value: '0 a 10', label: `0 ${formatMessage({ id: 'opt.to' })} 10` },
											{ value: '20 a 50', label: `20 ${formatMessage({ id: 'opt.to' })} 50` },
											{ value: '50 a 100', label: `50 ${formatMessage({ id: 'opt.to' })} 100` },
											{ value: 'Más de 100', label: formatMessage({ id: 'opt.moreThan100' }) },
										]}
									/>
								</>
							)}

							<LabelRadio>
								<p>{formatMessage({ id: 'forms.largeRevenue' })}</p>
							</LabelRadio>
							<Grid
								columns="2"
								margin="0 0 20px 0">
								<Radio
									label={formatMessage({ id: 'commons.yes' })}
									name="largeRevenue"
									itemValue="yes"
									quitImage={true}
								/>
								<Radio
									label="No"
									name="largeRevenue"
									itemValue="no"
									quitImage={true}
								/>
							</Grid>
							{formik.values.largeRevenue === 'yes' && (
								<ExtraData>
									<p>{formatMessage({ id: 'forms.largeRevenueM1' })}</p>
									<p>{formatMessage({ id: 'forms.largeRevenueM2' })}</p>
									<ContactButtons>
										<a href="https://www.wilma.mx/#contacto">
											{formatMessage({ id: 'commons.contactUs' })}
										</a>
										<a
											href="https://wa.me/525568010417"
											target="_blank"
											rel="noreferrer">
											Whatsapp
										</a>
									</ContactButtons>
								</ExtraData>
							)}
							{formik.values.largeRevenue === 'no' && (
								<>
									<Submit disable={formik.isSubmitting}>
										{formatMessage({ id: 'commons.next' })}
									</Submit>
								</>
							)}
						</Grid>
						{/* <pre>{JSON.stringify(formik.values, null, 4)}</pre>
						<pre>{JSON.stringify(formik.errors, null, 4)}</pre> */}
					</form>
				)}
			</Formik>
		</>
	);
};
const Intro = styled.div`
	h1 {
		font-size: 2rem;
		font-weight: 700;

		margin-bottom: 1em;
	}
	span {
		color: ${({ theme }) => theme.primaryColor};
	}
	p {
		font-size: 1.2rem;
		margin-bottom: 1em;
	}
`;
const LabelRadio = styled.div`
	margin: 10px 0 5px 0;
	padding-left: 5px;
`;

const ExtraData = styled.div`
	p {
		margin-bottom: 1em;
	}
`;
const ContactButtons = styled.div`
	display: flex;
	justify-content: center;
	gap: 20px;
	margin-top: 40px;
	a {
		line-height: 1.5;
		font-weight: 700;
		letter-spacing: 0.05rem;
		padding: 0.75rem 2.6rem 0.6rem 2.6rem;
		background-color: ${props => props.theme.primaryColor};
		color: #fff;
		border-radius: 50px;
		transition: all 0.2s ease-in;
		border: none;
		cursor: pointer;
		&:hover {
			color: #fff;
			background-color: ${props => props.theme.primaryHoverColor};
		}
		&:disabled {
			opacity: 0.5;
			&:hover {
				transform: scale(1);
			}
		}
	}
`;
export default Step3;
