import MenuOptions from 'components/common/MenuOptions';
import { MenuItem } from '@szhsin/react-menu';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
const Options = props => {
	const { id } = props;
	const navigate = useNavigate();
	const { formatMessage } = useIntl();

	// cuando den ver guardar el id en el local storage
	const handleView = id => {
		localStorage.setItem('allyId', id);
		window.location.reload();
	};

	return (
		<Wrap>
			<MenuOptions>
				<MenuItem>
					<MenuItemWrap onClick={e => navigate(`/admin/allies/edit/${id}`)}>
						<p>{formatMessage({ id: 'opt.edit' })}</p>
						<i className="material-icons-outlined">edit</i>
					</MenuItemWrap>
				</MenuItem>
				<MenuItem>
					<MenuItemWrap onClick={e => handleView(id)}>
						<p>{formatMessage({ id: 'opt.view' })}</p>
						<i className="material-icons-outlined">visibility</i>
					</MenuItemWrap>
				</MenuItem>
			</MenuOptions>
		</Wrap>
	);
};

const Wrap = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
`;
const MenuItemWrap = styled.div`
	width: 100%;
	padding: 0.375rem 1.5rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	i {
		font-size: 0.9rem;
	}
`;
export default Options;
