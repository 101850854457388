import BigImageLayout from '../components/common/BigImageLayout';
import RegisterForm from '../components/register/RegisterForm';
const Register = () => {
	return (
		<BigImageLayout url="/images/register.jpg">
			<RegisterForm />
		</BigImageLayout>
	);
};

export default Register;
