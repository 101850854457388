import Slider from './Slider';
import Policy from './Policy';
import styled from 'styled-components';
import { useFetch } from '../../../hooks/useFetch';
import Skeleton from '../../common/Skeleton';
import { useIntl } from 'react-intl';
const MyPolices = () => {
	const { data, isLoading } = useFetch('police/active/user');
	const { formatMessage } = useIntl();
	return (
		<>
			<div>
				<Title>{formatMessage({ id: 'account.membership' })}</Title>
				<Content>
					{isLoading && <Skeleton count={9} />}
					{!isLoading && data.length > 0 && (
						<Slider>
							{data.map(police => {
								return (
									<Policy
										key={police._id}
										{...police}
									/>
								);
							})}
						</Slider>
					)}
					{!isLoading && data.length === 0 && (
						<NoData>
							<h3>{formatMessage({ id: 'account.noMembership' })}</h3>
						</NoData>
					)}
				</Content>
			</div>
		</>
	);
};
const Title = styled.h2`
	padding-left: 10px;
	font-size: 1.5rem;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	overflow: hidden;
`;
const Content = styled.div`
	background-color: #fff;
	padding: 20px;
	border-radius: 5px;
	height: auto;
	box-shadow:
		0 19px 38px rgba(0, 0, 0, 0.1),
		0 15px 12px rgba(0, 0, 0, 0.12);
`;
const NoData = styled.div`
	background: black;
	min-height: 220px;
	border-radius: 15px;
	margin: 0 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fff;
`;
export default MyPolices;
