import styled from 'styled-components';
import ChangeLanguage from './changeLanguage';
import LanguageChanger from 'layout/Header/languageChanger';
const BigImageLayout = props => {
	const { children, url } = props;
	return (
		<Wrap>
			<Content>
				<LanguageChanger isRelative={true} />
				{children}
			</Content>
			<Image url={url} />
		</Wrap>
	);
};
const Wrap = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	height: 100vh;
	overflow: hidden;
	position: relative;
`;
const Content = styled.section`
	width: 100%;
	height: auto;
	height: 100vh;
	background: rgb(245 245 247);
	padding: 5%;
	overflow: auto;
`;
const Image = styled.aside`
	width: 100%;
	height: 100vh;
	position: relative;
	background: url(${props => props.url}) no-repeat center center;
	background-size: cover;
`;
export default BigImageLayout;
