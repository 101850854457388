import styled from 'styled-components';
import userService from 'services/userService';
import { useIntl } from 'react-intl';
const LinkCreator = () => {
	const { membership } = userService.getUser();
	const { formatMessage } = useIntl();

	const copyToClipboard = () => {
		const referralLink = `https://shop.wilma.mx/registro?wilmaId=${membership}`;
		navigator.clipboard
			.writeText(referralLink)
			.then(() => {
				alert(formatMessage({ id: 'dashboard.clipboardLink' }));
			})
			.catch(err => {
				console.error('Error al copiar el texto: ', err);
			});
	};
	return (
		<Wrap>
			<button onClick={copyToClipboard}>Copia tu link de referente</button>
		</Wrap>
	);
};

const Wrap = styled.div`
	text-align: right;
	margin-bottom: 2rem;
	button {
		border: none;
		background: ${({ theme }) => theme.primaryColor};
		padding: 10px 20px;
		color: #fff;
		border-radius: 10px;
		cursor: pointer;
	}
`;
export default LinkCreator;
