import Intro from './Intro';
import Grid from '../../common/Grid';
import MyPolices from './MyPolices';
import Profile from './Profile';
import NoPayPolices from './NoPayPolices';

const ClientDash = () => {
	return (
		<>
			<Intro />
			<Grid
				columns="2"
				margin="0 0 50px 0"
				justify="strech">
				<Profile />
				<MyPolices />
			</Grid>
			<NoPayPolices />
		</>
	);
};
export default ClientDash;
