let routes = [
	{
		id: '1',
		url: '/admin',
		icon: 'dashboard',
		title: 'dashboard.title',
		roles: ['root', 'partner', 'seller'],
	},
	{
		id: '3',
		url: '/admin/allies',
		icon: 'verified',
		title: 'allies.title',
		roles: ['root'],
	},
	{
		id: '4',
		url: '/admin/guides',
		icon: 'familiar_face_and_zone',
		title: 'guides.title',
		roles: ['root', 'partner'],
	},
	{
		id: '2',
		url: '/admin/members',
		icon: 'person',
		title: 'members.title',
		roles: ['root', 'partner', 'seller'],
	},
	{
		id: '5',
		url: '/admin/memberships',
		icon: 'description',
		title: 'memberships.title',
		roles: ['root', 'partner', 'seller'],
	},
	{
		id: '6',
		url: '/admin/quotes',
		icon: 'request_quote',
		title: 'quotes.title',
		roles: ['root', 'partner', 'seller'],
	},
	{
		id: '7',
		url: '/admin/claims',
		icon: 'storm',
		title: 'claims.title',
		roles: ['root'],
	},
	{
		id: '8',
		url: '/admin/manual-payment',
		icon: 'paid',
		title: 'payments.manual',
		roles: ['root'],
	},
];
export default routes;
