import styled from 'styled-components';
import Avatar from 'react-avatar';
import Menu from 'components/common/Menu';
import { MenuItem } from '@szhsin/react-menu';
import userService from '../../services/userService';
import { useIntl } from 'react-intl';
import { useToogleLanguage } from '../../hooks/useToogleLanguage';
const AvatarMenu = () => {
	const { name } = userService.getUser();
	const { formatMessage } = useIntl();
	const { languageChanger, state } = useToogleLanguage();
	return (
		<>
			<Wrap>
				<Avatar
					size="40"
					round={true}
					color="#F8F8F8"
					fgColor="#4B566B"
					name={name}
				/>

				<Menu>
					{/* <MenuItem>
						{state !== 'es' && (
							<MenuItemWrap onClick={() => languageChanger('es')}>Español</MenuItemWrap>
						)}
						{state !== 'en' && (
							<MenuItemWrap onClick={() => languageChanger('en')}>English</MenuItemWrap>
						)}
					</MenuItem> */}
					<MenuItem onClick={e => userService.logOut()}>
						{formatMessage({ id: 'logout.title' })}
					</MenuItem>
				</Menu>
			</Wrap>
		</>
	);
};
const Wrap = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	h2 {
		margin-bottom: 0;
		font-weight: 400;
		font-size: 0.8rem;
		color: ${props => props.theme.contentFontColor};
		margin-right: 1em;
	}
`;
export const MenuItemWrap = styled.div`
	width: 100%;
`;

export default AvatarMenu;
