import styled from 'styled-components';
import userService from '../../../services/userService';
import Button from '../../common/Button';
import { useIntl } from 'react-intl';
const Intro = () => {
	const { name } = userService.getUser();
	const { formatMessage } = useIntl();
	return (
		<Wrap>
			<div>
				<h3>{formatMessage({ id: 'account.welcome' })}</h3>
				<h1>{name}</h1>
			</div>
			<div>
				<Button
					to="/crear-poliza"
					margin="0">
					{formatMessage({ id: 'commons.suscribeMe' })}
				</Button>
			</div>
		</Wrap>
	);
};
const Wrap = styled.section`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 4em;
	h1 {
		font-size: 2rem;
		margin: 0;
		text-transform: capitalize;
	}
	h3 {
		font-size: 16px;
		font-weight: 400;
		margin: 0;
	}
`;
export default Intro;
