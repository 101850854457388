import Title from 'components/common/Title';
import LinkButton from 'components/common/LinkButton';
import { useIntl } from 'react-intl';
import Content from 'components/common/Content-v2';
import GuidesTable from 'components/tables/guides';
import userService from 'services/userService';
import CircleButton from 'components/common/ButtonIcon';
const Members = () => {
	const { formatMessage } = useIntl();
	const userRole = userService.getRole();
	const allyId = localStorage.getItem('allyId');

	const handleBack = () => {
		localStorage.removeItem('allyId');
		window.location.reload();
	};
	return (
		<>
			<Title>
				<h1>{formatMessage({ id: 'guides.title' })}</h1>
				{userRole === 'root' && (
					<LinkButton to="/admin/guides/add">{formatMessage({ id: 'commons.add' })}</LinkButton>
				)}
				{allyId && (
					<CircleButton
						type="button"
						icon="arrow_back"
						onClick={() => handleBack()}>
						{formatMessage({ id: 'commons.back' })}
					</CircleButton>
				)}
			</Title>
			<Content>
				<GuidesTable />
			</Content>
		</>
	);
};
export default Members;
