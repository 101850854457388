import styled from 'styled-components';
const Grid = props => {
	return <Wrap {...props}>{props.children}</Wrap>;
};
const Wrap = styled.div`
	width: 100%;
	display: grid;
	grid-template-columns: repeat(${({ columns }) => (columns ? columns : '3')}, minmax(0, 1fr));
	align-items: ${({ justify }) => (justify ? justify : 'start')};
	grid-column-gap: 1.5rem;
	grid-row-gap: 1.5rem;
	margin: ${({ margin }) => (margin ? margin : '0')};
	${props => {
		if (props.columns === '2') {
			return `
        @media screen and (max-width: 768px) {
          grid-template-columns: repeat(2, minmax(0, 1fr));
        }
        @media screen and (max-width: 480px) {
          grid-template-columns: repeat(1, minmax(0, 1fr));
        }
      `;
		}
		if (props.columns === '3') {
			return `
        @media screen and (max-width: 768px) {
          grid-template-columns: repeat(2, minmax(0, 1fr));
        }
        @media screen and (max-width: 480px) {
          grid-template-columns: repeat(1, minmax(0, 1fr));
        }
      `;
		}
		if (props.columns === '4') {
			return `
        @media screen and (max-width: 1024px) {
          grid-template-columns: repeat(3, minmax(0, 1fr));
        }
        @media screen and (max-width: 768px) {
          grid-template-columns: repeat(2, minmax(0, 1fr));
        }
        @media screen and (max-width: 480px) {
          grid-template-columns: repeat(1, minmax(0, 1fr));
        }
      `;
		}
	}}
`;
export default Grid;
