import styled from 'styled-components';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Grid from '../common/Grid';
import Input from '../common/forms/Input';
import Submit from '../common/forms/Submit';
import api from '../../services/api';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
const Form = () => {
	const navigate = useNavigate();
	const { formatMessage } = useIntl();
	return (
		<Wrap>
			<Logo>
				<img
					src="/images/wilma.png"
					alt="Wilma"
				/>
			</Logo>
			<Title>
				<h1>
					<span>{formatMessage({ id: 'msg.forgotPasswordTitle' })}</span>
				</h1>
				<p>{formatMessage({ id: 'msg.forgotPassword' })}</p>
			</Title>
			<Formik
				initialValues={{
					email: '',
				}}
				validationSchema={Yup.object({
					email: Yup.string()
						.email(formatMessage({ id: 'errors.invalidEmail' }))
						.required(formatMessage({ id: 'errors.required' })),
				})}
				onSubmit={async (values, { setSubmitting }) => {
					setSubmitting(true);
					try {
						await api.post('/email-confirmation/sendRecoveryPassword', values);
						toast.success('Se ha enviado un correo con la liga para recuperar tu contraseña');
						navigate('/login');
						setSubmitting(false);
					} catch (error) {
						toast.error('Ocurrió un error al enviar el correo, intenta nuevamente.');
						setSubmitting(false);
					}
				}}>
				{formik => (
					<form onSubmit={formik.handleSubmit}>
						<Grid columns="1">
							<Input
								name="email"
								type="email"
								label={formatMessage({ id: 'forms.email' })}
								placeholder={formatMessage({ id: 'forms.emailLabel' })}
							/>
						</Grid>
						<Extra>
							<Grid
								columns="2"
								align="center">
								<div>
									<ForgotPassword href="/login">
										{formatMessage({ id: 'forms.login' })}
									</ForgotPassword>
								</div>
								<Submit
									margin="0"
									justify="end"
									disabled={formik.isSubmitting}>
									{formatMessage({ id: 'forms.recover' })}
								</Submit>
							</Grid>
						</Extra>
					</form>
				)}
			</Formik>
		</Wrap>
	);
};

const Wrap = styled.div`
	height: 100%;
`;

const Logo = styled.div`
	margin-bottom: 2em;
	img {
		display: block;
		max-width: 140px;
	}
`;

const Title = styled.div`
	margin-top: 100px;
	margin-bottom: 40px;
	h1 {
		font-size: 2.5rem;
		font-weight: bold;
		color: #000;
		margin: 0;
		span {
			color: ${props => props.theme.primaryColor};
		}
	}
	p {
		margin: 0;
		font-size: 1.25rem;
		color: #000;
	}
	@media screen and (max-width: 768px) {
	}
`;
const Extra = styled.div`
	margin-top: 2em;
`;
const ForgotPassword = styled.a`
	color: ${props => props.theme.primaryColor};
	font-weight: bold;
	font-size: 16px;
	text-decoration: underline;
	display: block;
	margin-bottom: 5px;
	&:hover {
		color: ${props => props.theme.primaryHoverColor};
	}
	@media screen and (max-width: 768px) {
		font-size: 10px;
	}
`;

export default Form;
