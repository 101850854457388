import { useState, useEffect } from 'react';
import moment from 'moment';
import api from '../services/api';
export const useDataTable = (url, extraParams = [{ name: null, value: null }], refresh = null) => {
	const [isLoading, setIsLoading] = useState(true);
	const [response, setResponse] = useState([]);
	const [totalDocs, setTotalDocs] = useState(0);
	const [perPage, setPerPage] = useState(10);
	const [date, setDate] = useState(moment().format('YYYY-MM-DD'));
	const [page, setPage] = useState(1);
	const [search, setSearch] = useState('');
	const [showHidden, setShowHidden] = useState(true);
	const createUrl = page => {
		const urlWithParams = new URL(`${process.env.REACT_APP_API}/${url}`);
		urlWithParams.searchParams.set('page', page);
		urlWithParams.searchParams.set('limit', perPage);
		if (extraParams && extraParams.length > 0) {
			extraParams.map(param => {
				if (param.value) urlWithParams.searchParams.set(param.name, param.value);
			});
		}
		return urlWithParams;
	};
	const fetchData = async page => {
		try {
			setIsLoading(true);
			let urlParams = createUrl(page);
			let response = await api.get(urlParams);
			setResponse(response.docs);
			setTotalDocs(response.totalDocs);
			setIsLoading(false);
		} catch (err) {
			console.log(err);
		}
	};
	const handlePageChange = page => {
		setPage(page);
		fetchData(page);
	};
	const handlePerRowsChange = async (newPerPage, page) => {
		try {
			setIsLoading(true);
			let urlParams = createUrl(page);
			let response = await api.get(urlParams);
			setResponse(response.docs);
			setPerPage(newPerPage);
			setIsLoading(false);
		} catch (err) {
			console.log(err);
		}
	};
	useEffect(() => {
		fetchData(page);
		// eslint-disable-next-line
	}, [search, date, showHidden, perPage, extraParams[0].value, refresh]);

	return {
		isLoading,
		response,
		totalDocs,
		perPage,
		page,
		handlePageChange,
		handlePerRowsChange,
		setSearch,
		date,
		setDate,
		showHidden,
		setShowHidden,
		fetchData,
	};
};
