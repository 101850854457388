import { toast } from 'react-toastify';
import api from '../services/api';
import userService from '../services/userService';
import { useNavigate } from 'react-router-dom';
export const useLogin = () => {
	let navigate = useNavigate();
	const performLogin = async data => {
		try {
			const token = await api.post('/auth/login', data);
			userService.setToken(token.token);
			const user = await api.get('/auth/current-user');
			if (user) {
				userService.setUser(user);
				if (user.role !== 'client') {
					navigate('/');
				} else if (user.role === 'client' && user?.policiesCount === 0) {
					navigate('/crear-poliza');
				} else {
					navigate('/');
				}
			} else {
				userService.setToken(null);
			}
		} catch (err) {
			userService.setToken(null);
			let message = 'Error inesperado';
			if (err.response && err.response.data && err.response.data.message) {
				message = err.response.data.message;
			}
			toast.error(message);
		}
	};
	return { performLogin };
};
