import React, { useState } from 'react';
import styled from 'styled-components';
import LoadingSmall from './LoadingSmall';
import api from '../../services/api';
import { useIntl } from 'react-intl';
const DownloadPolice = props => {
	const [isLoading, setIsLoading] = useState(false);
	const { formatMessage } = useIntl();
	const download = async () => {
		try {
			setIsLoading(true);
			const response = await api.get(`/pdf/downloadCar/${props.id}`, {
				responseType: 'blob',
			});

			const url = window.URL.createObjectURL(new Blob([response]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `${props.id}.pdf`);
			document.body.appendChild(link);
			link.click();
			setIsLoading(false);
		} catch (error) {
			console.log(error);
		}
	};
	return (
		<Wrap {...props}>
			<MyButton
				onClick={download}
				type="submit"
				disabled={isLoading}
				{...props}>
				{isLoading ? <LoadingSmall /> : formatMessage({ id: 'commons.download' })}
			</MyButton>
		</Wrap>
	);
};
const Wrap = styled.div`
	width: 100%;
	display: flex;
	justify-content: ${({ justify }) => (justify ? justify : 'center')};
`;
const MyButton = styled.button`
	line-height: 1.5;
	font-weight: 700;
	letter-spacing: 0.05rem;
	padding: 0.5rem 1rem;
	background-color: ${props => props.theme.primaryColor};
	color: #fff;
	border-radius: 50px;
	transition: all 0.2s ease-in;
	border: none;
	cursor: pointer;
	@media screen and (max-width: 768px) {
		padding: 0.75rem 0.5rem 0.6rem 0.5rem;
		line-height: 1;
		min-width: 40%;
	}
	&:hover {
		color: #fff;
		background-color: ${props => props.theme.primaryHoverColor};
	}
	&:disabled {
		opacity: 0.5;
		&:hover {
			background-color: ${props => props.theme.primaryColor};
		}
	}
`;

export default DownloadPolice;
