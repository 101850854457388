import Content from 'components/common/Content-v2';
import MainTitle from 'components/common/Title';
import LinkButton from 'components/common/LinkButton';
import { useFetch } from 'hooks/useFetch';
import Skeleton from 'components/common/Skeleton';
import { useParams } from 'react-router-dom';
import EditGuidesForm from 'components/forms/guides/edit';
import { useIntl } from 'react-intl';
const EditMember = props => {
	const { formatMessage } = useIntl();
	const { id } = useParams();
	const { data, isLoading } = useFetch(`user/${id}`);
	return (
		<>
			<MainTitle>
				<h1>{formatMessage({ id: 'forms.editGuide' })}</h1>
				<LinkButton to="/admin/guides"> {formatMessage({ id: 'commons.back' })}  </LinkButton>
			</MainTitle>
			<Content>
				{isLoading && <Skeleton count={10} />}
				{!isLoading && <EditGuidesForm {...data} />}
			</Content>
		</>
	);
};
export default EditMember;
