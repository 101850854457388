import { Menu } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import styled from 'styled-components';

const MenuOptions = props => {
	const { children } = props;
	return (
		<Wrap>
			<Menu
				menuButton={<i className="material-icons-outlined">more_vert</i>}
				transition
				direction="left"
				align="center"
				position="auto"
				arrow={true}>
				{children}
			</Menu>
		</Wrap>
	);
};

export const Wrap = styled.div`
	cursor: pointer;
	.szh-menu__item {
		padding: 0;
	}
`;

export default MenuOptions;
