import axios from 'axios';
import { setupInterceptorsTo } from './interceptors';
const api = axios.create({
	baseURL: `${process.env.REACT_APP_API}`,
	credentials: true,
	headers: {
		Accept: 'application/json',
		'Content-Type': 'application/json',
	},
});
setupInterceptorsTo(api);

// api.interceptors.response.use(
// 	response => {
// 		return response.data;
// 	},
// 	error => {
// 		console.log(error);
// 		const baseUrl = error.response.config.baseURL;
// 		const incomingUrl = error.response.config.url;
// 		const cut = incomingUrl.replace(baseUrl, '');
// 		if (error.response.status === 401 && cut !== 'signIn') {
// 			UserService.logOut()
// 				.then(result => {
// 					localStorage.removeItem('user');
// 					window.location.replace('/login');
// 				})
// 				.catch(e => {
// 					console.log(e);
// 					window.location.replace('/login');
// 				});
// 		} else {
// 			//TODO:
// 			return Promise.reject(error);
// 		}
// 	}
// );

export default api;
