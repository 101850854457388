import { useContext } from 'react';
import { InsuranceContext } from '../../context/insurance';
import Step1 from './steps/Step1';
import Step2 from './steps/Step2';
import Step3 from './steps/Step3';
import Step4 from './steps/Step4';
import Step5 from './steps/Step5';
import BigImageLayout from 'components/common/BigImageLayout';

const InsuranceWrap = () => {
	const { state } = useContext(InsuranceContext);
	return (
		<>
			{state.step === 1 && (
				<BigImageLayout url="/images/step1.jpg">
					<Step1 />
				</BigImageLayout>
			)}
			{state.step === 2 && (
				<BigImageLayout url="/images/step2.jpg">
					<Step2 />
				</BigImageLayout>
			)}
			{state.step === 3 && (
				<BigImageLayout url="/images/step3.jpg">
					<Step3 />
				</BigImageLayout>
			)}
			{state.step === 4 && (
				<BigImageLayout url="/images/step4.jpg">
					<Step4 />
				</BigImageLayout>
			)}
			{state.step === 5 && (
				<BigImageLayout url="/images/login.jpg">
					<Step5 />
				</BigImageLayout>
			)}
		</>
	);
};

export default InsuranceWrap;
