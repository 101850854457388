import Grid from '../../common/Grid';
import PolicyUnpaid from './PolicyUnpaid';
import styled from 'styled-components';
import { useFetch } from '../../../hooks/useFetch';
import Skeleton from '../../common/Skeleton';
import { useIntl } from 'react-intl';
const MyPolices = () => {
	const { data, isLoading } = useFetch('/police/inactive/user');
	const { formatMessage } = useIntl();
	return (
		<>
			<Title>{formatMessage({ id: 'account.completeProcess' })}</Title>
			{isLoading && <Skeleton count={9} />}
			{!isLoading && data.length > 0 && (
				<Grid columns="2">
					{data.map(police => {
						return (
							<PolicyUnpaid
								key={police._id}
								{...police}
							/>
						);
					})}
				</Grid>
			)}
			{!isLoading && data.length === 0 && (
				<NoData>
					<h3>{formatMessage({ id: 'account.noProcess' })}</h3>
				</NoData>
			)}
		</>
	);
};
const Title = styled.h2`
	padding-left: 10px;
	font-size: 1.5rem;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	overflow: hidden;
	margin-bottom: 1em;
`;

const NoData = styled.div`
	background: black;
	min-height: 220px;
	border-radius: 15px;
	margin: 0 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fff;
`;
export default MyPolices;
