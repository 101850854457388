import { useState } from 'react';
import styled from 'styled-components';
import Title from 'components/common/Title';
import { useIntl } from 'react-intl';
import Content from 'components/common/Content-v2';

const Claims = () => {
	const { formatMessage } = useIntl();
	const [type, setType] = useState('afectation');
	return (
		<>
			<Title>
				<h1>{formatMessage({ id: 'claims.title' })}</h1>
			</Title>
			<Tabs>
				<Tab
					className={type === 'afectation' ? 'active' : ''}
					onClick={() => setType('afectation')}>
					Probable afectación
				</Tab>
			</Tabs>
			<Content borderRadius=" 0 .75rem  .75rem .75rem">
				{type === 'afectation' && <p>dklas</p>}
			</Content>
		</>
	);
};

export const Tabs = styled.div`
	display: flex;
`;
export const Tab = styled.div`
	font-size: 1.2em;
	padding: 0.5em 1em 0.5em 1em;
	border-radius: 5px 5px 0 0;
	background-color: ${props => props.theme.menuLines};
	cursor: pointer;
	&.active {
		background: ${props => props.theme.background};
	}
`;
export default Claims;
