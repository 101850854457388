import { Intro } from '../common/styles/Intro';
import styled from 'styled-components';

const RegisterForm = () => {
	return (
		<>
			<Wrap>
				<Intro>
					<h1>
						¡Te hemos mandado un <span>correo!</span>
					</h1>
					<p>Haz click en el enlace para que puedas comprobar tu cuenta</p>
					<p>Una vez comprobado podrás seguir con tu proceso de contratación</p>
				</Intro>
			</Wrap>
		</>
	);
};
const Wrap = styled.div`
	display: flex;
	align-items: center;
	height: 100%;
`;

export default RegisterForm;
